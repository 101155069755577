import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { BwicSearchResult } from "../types/bwic-monitor/BwicSearchResult";
import { bwicService } from "../services";
import { allBWICSActions, pushBwicActions } from "../actions";
import { ActionType } from "typesafe-actions";
import { AppState } from "../types/state/AppState";
import { Bid } from "../types/bidding/Bid";

interface BidsToUpdate {
    bwicReferenceName: string,
    positionId: number;
    bids: Bid[]
}

function* watchBwicStatusChange(action: ActionType<typeof pushBwicActions.bwicStatusChanged>) {
    const originalBwic: BwicSearchResult = yield select((s: AppState) => s.allBWICS.bwics?.find(b => b.referenceName === action.bwicReferenceName))
    if (originalBwic && (originalBwic.isMy || originalBwic.isParticipant)) {
        const freshBwic: BwicSearchResult = yield call(bwicService.getByReferenceName, action.bwicReferenceName);
        const bidsToUpdate: BidsToUpdate[] = freshBwic.securities
            .filter(s => s.bids?.length)
            .map(s => ({ bwicReferenceName: action.bwicReferenceName, positionId: s.id, bids: s.bids }))

        yield all(bidsToUpdate.map(b => put(allBWICSActions.addPositionBids(b.bwicReferenceName, b.positionId, b.bids))))
    }
}

export function* watchAllBwics() {
    yield takeEvery(pushBwicActions.bwicStatusChanged, watchBwicStatusChange);
}
