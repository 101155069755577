import { getType } from 'typesafe-actions';
import { saveAs } from 'file-saver';
import { AnyAction } from 'redux';
import { cloneDeep, isNil, noop } from 'lodash';
import { call, all, put, select, takeEvery, takeLatest, take } from 'redux-saga/effects';
import { bdInventoryActions } from '../actions/bd-inventory.actions';
import { errorActions } from '../actions';
import { RequestState } from '../constants/request-state';
import { PageConfigType } from '../types/page-config/PageConfigType';
import { AppState } from '../types/state/AppState';
import { inventoryService } from '../services/inventory.service';
import { accountActions } from '../constants';
import { UserConfigFilter } from '../types/user-config/UserConfigFilter';
import { arrayUtils, isRequestNone, shouldRequest } from '../utils';
import { InventoryPositionStatus } from '../types/inventory/InventoryPositionStatus';
import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { InventoryListFilterState } from '../types/state/BdInventoryState';
import {
    InventorySecurityOrderByColumn,
    InventorySecuritySortOrder
} from '../types/inventory/InventorySecurityOrderByColumn';
import { accountService } from '../services';
import { user } from '../user';
import { selectedDateOptions } from '../utils/amr-pipeline-filter.utils';
import {
    ratings, inventoryStatuses, identifiers, dateFrom, dateTo, multipleCurrencies, collateralTypes, collateralManagers,
    esg, euCompliance, staticDeals, reinvestmentEndFrom, reinvestmentEndTo, nonCallEndFrom, nonCallEndTo, currentInventory,
    mixedDateRange, trustees, amr, outOfNC, outOfRI
} from '../utils/filtering/serializers/serializer.definitions';
import { QueryStringArgumentSerializer } from '../utils/filtering/serializers/QueryStringArgumentSerializer';
import { Rating } from '../types/enums/Rating';
import { queryStringSerializer } from '../utils/filtering/query-string-serializer';
import { searchSecuritiesActions } from '../actions';
import { getInventoryPositionStatusByTitle } from '../components/inventory/inventory-position-status-title';
import { Currency } from '../types/enums/Currency';
import { bdInventoryFilters } from '../components/inventory/bd/filter/bdInventoryFilters';
import { createFilterActions } from '../actions/filter.actions';
import { BwicFilterType, TFilter } from '../types/filters/FilterState';
import { CollateralType } from '../types/amr-pipeline/enums/CollateralType';
import { dateRangeFilterOptions } from '../constants/date-range.filter';
import { convertToApiCriteria, getDateOptionByRange, getYearsRange } from '../utils/filtering/filter.utils';
import { Company } from '../types/amr-pipeline/models/Company';
import { DeserializeCommandHandler } from '../utils/filtering/serializers/DeserializeCommand';
import { SubscriptionFeature } from '../types/billing/SubscriptionFeature';
import { history } from '../history';

const filterActions = createFilterActions(BwicFilterType.BDInventory);

function* watchInit() {
    yield put(bdInventoryActions.requestInventoryExistFlag());
    yield put(bdInventoryActions.loadBdInventory());

    yield take(getType(bdInventoryActions.requestResult));

    const queryString: string | undefined = history.location.search?.trim();
    const managers: Company[] = yield select((state: AppState) => state.entities.collateralManagers.items);

    const filter: TFilter<BwicFilterType.BDInventory> = yield select((s: AppState) => s.filters.bdInventory.filter);
    // Call init after all dynamic filters (managers, broker-dealers) have already been loaded.

    const initialFilter = cloneDeep(filter);
    const inventories: InventoryPosition[] = yield select((s: AppState) => s.entities.inventory.items)
    const allTrusteeNames = inventories.map(s => ({ legalName: s.trustee?.legalName, referenceName: s.trustee?.referenceName }));
    const trusteeFilterItems = arrayUtils
        .distinct(allTrusteeNames, d => d.referenceName)
        .filter(name => !!name.legalName)
        .sort((a, b) => String(a.legalName).localeCompare(String(b.legalName)))
        .map(d => ({ value: d.referenceName, text: d.legalName, selected: false, visible: true, disabled: false }));
    initialFilter.trustees = { ...initialFilter.trustees, filter: trusteeFilterItems };
    yield put(filterActions.init({ ...initialFilter, date: bdInventoryFilters.defaultFilter.date }));

    const filtersConfig: UserConfigFilter[] = yield select((s: AppState) => s.entities.pageConfig.configs?.[PageConfigType.Inventory]?.filters);
    const canUseAdvancedFilters = user.hasFeatures(SubscriptionFeature.InventoryAvancedFilters);
    const defaultConfigFilter = canUseAdvancedFilters ? (filtersConfig || []).find(filter => filter.default): undefined;

    if (queryString) {
        const [ncEndSerializers, getNcEndDeserializeResult] = mixedDateRange('nonCallEndFrom', 'nonCallEndTo');
        const [riEndSerializers, getRiEndDeserializeResult] = mixedDateRange('reinvestmentEndFrom', 'reinvestmentEndTo');

        let from: Date | undefined | null = undefined;
        let to: Date | undefined | null = undefined;

        let statusOptions: (InventoryPositionStatus | undefined)[] = [];
        let ratingsOptions: Rating[] = [];
        let identifierList: string[] = [];
        let currencyOptions: Currency[] = [];
        let collateralTypeOptions: CollateralType[] = [];
        let collateralManagersReferenceNames: string[] = [];
        let isEsg: boolean | undefined = undefined;
        let isEuCompilance: boolean | undefined = undefined;
        let isStaticDeals: boolean | undefined = undefined;
        let maturityFrom: Date | null = null;
        let maturityTo: Date | null = null;
        let vintageFrom: Date | null = null;
        let vintageTo: Date | null = null;
        let closingFrom: Date | null = null;
        let closingTo: Date | null = null;
        let trusteesValues: string[] = [];
        let amrValue: boolean | undefined = undefined;
        let isOutofRI: boolean | undefined = undefined;
        let isOutOfNC: boolean | undefined = undefined;

        const serializers: QueryStringArgumentSerializer<any>[] = [
            dateFrom(parsedDate => from = parsedDate),
            dateTo(parsedDate => to = parsedDate),
            inventoryStatuses(parsedStatuses =>
                statusOptions = parsedStatuses.map(s => getInventoryPositionStatusByTitle(s))
            ),
            identifiers(list => identifierList = list),
            ratings(parsedRatings => ratingsOptions = parsedRatings.map(r => Rating[r as keyof typeof Rating])),
            multipleCurrencies(parsedCurrency => currencyOptions = parsedCurrency.map(c => Currency[c as keyof typeof Currency])),

            collateralTypes(parsedCollateralTypes => collateralTypeOptions = parsedCollateralTypes as CollateralType[]),
            collateralManagers(values => collateralManagersReferenceNames = values, managers.map(m => m.referenceName)),
            esg(value => isEsg = value),
            euCompliance(parsedEuCompilance => isEuCompilance = parsedEuCompilance),
            staticDeals(parsedStaticDeals => isStaticDeals = parsedStaticDeals),
            outOfNC(value => isOutOfNC = value),
            outOfRI(value => isOutofRI = value),
            ...ncEndSerializers,
            ...riEndSerializers,
            dateFrom(value => maturityFrom = value, 'maturityFrom'),
            dateTo(value => maturityTo = value, 'maturityTo'),
            dateFrom(value => vintageFrom = value, 'vintageFrom'),
            dateTo(value => vintageTo = value, 'vintageTo'),
            dateFrom(value => closingFrom = value, 'closingFrom'),
            dateTo(value => closingTo = value, 'closingTo'),
            trustees(values => trusteesValues = values),
            amr(value => amrValue = value),
        ];

        queryStringSerializer.deserialize(queryString, serializers);

        const dateOption = getDateOptionByRange(dateRangeFilterOptions.CurrentInventory, from, to);
        // Set nonCallEnd and reinvestmentEnd values
        const ncEndDeserializeResult = getNcEndDeserializeResult();
        const riEndDeserializeResult = getRiEndDeserializeResult();

        const datesCommand = {
            canExecute: () =>
                canUseAdvancedFilters ||
                dateOption.key === dateRangeFilterOptions.CurrentInventory.key,
            execute: function* () {
                if (dateOption.key !== dateRangeFilterOptions.All.key) {
                    yield put(filterActions.filterDateSelectOption(dateOption, 'date'));
                }

                if (from || to) {
                    yield put(filterActions.filterDateSelectCustomRange({ from, to }, 'date'));
                }
            }
        };

        const ratingsCommand = {
            execute: function* () {
                yield all(ratingsOptions.map((r: Rating) => {
                    const ratingOption = bdInventoryFilters.defaultFilter.ratings.filter.filter(rating => String(rating.value) === r)[0];
                    return put(filterActions.filterSelectChange(ratingOption.value, 'ratings'))
                }));
            }
        };

        const identifiersCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!identifierList.length,
            execute: function* () {
                yield all(identifierList.map(i => put(searchSecuritiesActions.addSearchItem(i))));
            }
        }

        const statusesCommand = {
            execute: function* () {
                yield all(statusOptions.map((s: InventoryPositionStatus | undefined) => {
                    const statusOption = bdInventoryFilters.defaultFilter.statuses.filter.filter(status => Number(status.value) === s)[0];
                    return put(filterActions.filterSelectChange(statusOption.value, 'statuses'));
                }));
            }
        }

        const currenciesCommand = {
            shouldExecute: () => !!currencyOptions.length,
            execute: function* () {
                yield all(currencyOptions.map(
                    c =>
                        put(filterActions.filterSelectChange(c.toString(), 'currency'))
                ));
            }
        }

        const collateralTypesCommand = {
            shouldExecute: () => !!collateralTypeOptions.length,
            execute: function* () {
                yield all(collateralTypeOptions.map((collateralTypeOption: CollateralType) => put(filterActions.filterSelectChange(collateralTypeOption, 'collateralType'))));
            }
        }

        const esgCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isEsg),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('esg'));
                yield put(filterActions.filterRadioChange(isEsg!, 'esg'));
            }
        }

        const euCompilanceCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isEuCompilance),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('euCompliance'));
                yield put(filterActions.filterRadioChange(isEuCompilance!, 'euCompliance'));
            }
        }
        const staticDealsCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isStaticDeals),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('staticDeal'));
                yield put(filterActions.filterRadioChange(isStaticDeals!, 'staticDeal'));
            }
        }
        const outOfNCCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isOutOfNC),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('outOfNC'));
                yield put(filterActions.filterRadioChange(isOutOfNC!, 'outOfNC'));
            }
        }
        const outofRICommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(isOutofRI),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('outOfRI'));
                yield put(filterActions.filterRadioChange(isOutofRI!, 'outOfRI'));
            }
        }

        const nonCallEndCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!ncEndDeserializeResult?.dates || !!ncEndDeserializeResult?.years,
            execute: function* () {
                yield put(filterActions.filterDateQueryStringDeserialized('nonCallEnd', ncEndDeserializeResult))
            }
        };
        const reinvestmentEndCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!riEndDeserializeResult?.dates || !!riEndDeserializeResult?.years,
            execute: function* () {
                yield put(filterActions.filterDateQueryStringDeserialized('reinvestmentEnd', riEndDeserializeResult));
            }
        };

        const collateralManagersReferenceNamesCommand = {
            shouldExecute: () => !!collateralManagersReferenceNames.length,
            execute: function* () {
                yield all(collateralManagersReferenceNames.map((cm: string) => {
                    return put(filterActions.filterSelectChange(cm, 'managers'));
                }));
            }
        }

        const trusteesValuesCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !!trusteesValues.length,
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('trustees'));
                yield all(trusteesValues.map(name => put(filterActions.filterSelectChange(name, 'trustees'))));
            }
        }

        const maturityCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(maturityFrom) || !isNil(maturityTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('maturity'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, maturityFrom, maturityTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'maturity'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: maturityFrom, to: maturityTo }, 'maturity'),
                );
            }
        }

        const vintageCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(vintageFrom) || !isNil(vintageTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('vintage'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, vintageFrom, vintageTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'vintage'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: vintageFrom, to: vintageTo }, 'vintage'),
                );
            }
        }

        const closingCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(closingFrom) || !isNil(closingTo),
            execute: function* () {
                yield put(filterActions.makeFilterVisible('closing'));
                const filterOption = getDateOptionByRange(dateRangeFilterOptions.ThisWeek, closingFrom, closingTo);
                yield put(filterActions.filterDateSelectOption(filterOption, 'closing'));
                yield put(
                    filterActions.filterDateSelectCustomRange({ from: closingFrom, to: closingTo }, 'closing'),
                );
            }
        }

        const amrValueCommand = {
            canExecute: () => canUseAdvancedFilters,
            shouldExecute: () => !isNil(amrValue),
            execute: function* () {
                yield put(filterActions.filterVisibilityChange('amr'));
                yield put(filterActions.filterRadioChange(amrValue!, 'amr'));
            }
        }

        const isAllCommandsExecuted: boolean =
            yield new DeserializeCommandHandler()
                .addCommand(datesCommand)
                .addCommand(identifiersCommand)
                .addCommand(statusesCommand)
                .addCommand(ratingsCommand)
                .addCommand(currenciesCommand)
                .addCommand(collateralTypesCommand)
                .addCommand(esgCommand)
                .addCommand(euCompilanceCommand)
                .addCommand(staticDealsCommand)
                .addCommand(outOfNCCommand)
                .addCommand(outofRICommand)
                .addCommand(nonCallEndCommand)
                .addCommand(reinvestmentEndCommand)
                .addCommand(collateralManagersReferenceNamesCommand)
                .addCommand(trusteesValuesCommand)
                .addCommand(maturityCommand)
                .addCommand(vintageCommand)
                .addCommand(closingCommand)
                .addCommand(amrValueCommand)
                .processGenerator()

        if (!isAllCommandsExecuted) {
            yield put(bdInventoryActions.advancedFilterBlocked(true));
            return;
        }

    } else if (defaultConfigFilter) {
        yield put(filterActions.selectFilterFromConfig(defaultConfigFilter))
        if(!canUseAdvancedFilters) {
            yield put(filterActions.filterDateSelectOption(dateRangeFilterOptions.CurrentInventory, 'date'));
        }
    } else {
        yield put(filterActions.filterDateSelectOption(dateRangeFilterOptions.CurrentInventory, 'date'));
    }
    yield put(bdInventoryActions.applyFilter());
}

function* watchRedirectWithFilterArguments() {
    const { filter } = yield select((state: AppState) => state.filters.bdInventory);
    const { searchTermItems } = yield select((state: AppState) => state.searchSecurities);
    const criteria = getFilterCriteria(filter, searchTermItems);
    const serializers: QueryStringArgumentSerializer<any>[] = [
        identifiers(),
        inventoryStatuses(),
        ratings(),
        dateFrom(),
        dateTo(),
        multipleCurrencies(),
        collateralTypes(),
        collateralManagers(),
        esg(),
        euCompliance(),
        staticDeals(),
        nonCallEndFrom(),
        nonCallEndTo(),
        reinvestmentEndFrom(),
        reinvestmentEndTo(),
        currentInventory(),
        dateFrom(noop, 'maturityFrom'),
        dateTo(noop, 'maturityTo'),
        dateFrom(noop, 'vintageFrom'),
        dateTo(noop, 'vintageTo'),
        dateFrom(noop, 'closingFrom'),
        dateTo(noop, 'closingTo'),
        trustees(),
        amr(),
        outOfNC(),
        outOfRI()
    ];

    const queryString = queryStringSerializer.serialize(criteria, serializers);

    if (queryString) {
        yield call(history.replace, history.location.pathname + '?' + queryString);
    }
    yield put(filterActions.applyFilter());
}

function getFilterCriteria(filter: InventoryListFilterState, isinCusipsAndTickers: string[]) {
    const nonCallEndYears = getYearsRange(filter.nonCallEnd);
    const reinvestmentEndYears = getYearsRange(filter.reinvestmentEnd);

    const nonCallEndDateRange = selectedDateOptions(filter.nonCallEnd);
    const reinvestmentEndDateRange = selectedDateOptions(filter.reinvestmentEnd);

    const defaultDateRange = { dateFrom: undefined, dateTo: undefined };

    const maturityRange = filter.maturity.filter.selectedOption
        ? convertToApiCriteria(filter.maturity.filter.selectedOption, filter.maturity.filter.options)
        : defaultDateRange;
    const vintageRange = filter.vintage.filter.selectedOption
        ? convertToApiCriteria(filter.vintage.filter.selectedOption, filter.vintage.filter.options)
        : defaultDateRange;
    const closingRange = filter.closing.filter.selectedOption
        ? convertToApiCriteria(filter.closing.filter.selectedOption, filter.closing.filter.options)
        : defaultDateRange;

    return {
        isinCusipsAndTickers,
        statuses: filter.statuses.filter.filter(s => s.selected).map(s => s.text),
        currency: filter.currency.filter.filter(s => s.selected).map(s => s.text),
        ratings: filter.ratings.filter.filter(r => r.selected).map(r => r.value),
        collateralTypes: filter.collateralType.filter.filter(r => r.selected).map(r => r.value),
        collateralManagers: filter.managers.filter.filter(c => c.selected).map(c => c.value),
        nonCallEndFrom: nonCallEndYears.from ?? nonCallEndDateRange.from,
        nonCallEndTo: nonCallEndYears.to ?? nonCallEndDateRange.to,
        reinvestmentEndFrom: reinvestmentEndYears.from ?? reinvestmentEndDateRange.from,
        reinvestmentEndTo: reinvestmentEndYears.to ?? reinvestmentEndDateRange.to,
        esg: filter.esg.filter.selectedOption,
        euCompliance: filter.euCompliance.filter.selectedOption,
        staticDeals: filter.staticDeal.filter.selectedOption,
        outOfNC: filter.outOfNC.filter.selectedOption,
        outOfRI: filter.outOfRI.filter.selectedOption,
        ...convertToApiCriteria(filter.date.filter.selectedOption ? filter.date.filter.selectedOption : dateRangeFilterOptions.CurrentInventory, filter.date.filter.options),
        maturityFrom: maturityRange.dateFrom,
        maturityTo: maturityRange.dateTo,
        vintageFrom: vintageRange.dateFrom,
        vintageTo: vintageRange.dateTo,
        closingFrom: closingRange.dateFrom,
        closingTo: closingRange.dateTo,
        trustees: filter.trustees?.filter.filter(f => f.selected).map(f => f.value),
        amr: filter.amr?.filter.selectedOption
    };
}

function* watchRequestInventoryExist() {
    try {
        const exist: boolean = yield call(inventoryService.getInventoryExistFlag);
        yield put(bdInventoryActions.inventoryExistFlag(exist));
    } catch (e) {
        yield put(bdInventoryActions.inventoryExistFlag(true));
    }
}

function* watchLoadBdInventory() {
    const requestState: RequestState = yield select((state: AppState) => state.entities.inventory.requestState);

    if (shouldRequest(requestState)) {
        yield put(bdInventoryActions.request());
        try {
            const response: { inventorySecurities: InventoryPosition[]; lockModifiedDate: Date; } = yield call(inventoryService.getBdInventory);
            yield put(bdInventoryActions.requestResult(RequestState.success, response));
        } catch (e) {
            yield put(errorActions.criticalError(e));
        }
    }
}

function* watchExportInventory(action: AnyAction) {
    const { sortField, sortDirection } = action.payload;
    const filter: InventoryListFilterState = yield select((state: AppState) => state.filters.bdInventory.lastAppliedFilter);
    const searchSecurities: string[] = yield select((state: AppState) => state.searchSecurities.searchTermItems);
    const dateRange = filter.date.filter.selectedOption
        ? convertToApiCriteria(filter.date.filter.selectedOption, filter.date.filter.options)
        : { dateFrom: undefined, dateTo: undefined };
    const maturityDateRange = filter.maturity.filter.selectedOption
        ? convertToApiCriteria(filter.maturity.filter.selectedOption, filter.maturity.filter.options)
        : { dateFrom: undefined, dateTo: undefined };
    const vintageDateRange = filter.vintage.filter.selectedOption
        ? convertToApiCriteria(filter.vintage.filter.selectedOption, filter.vintage.filter.options)
        : { dateFrom: undefined, dateTo: undefined };
    const closingDateRange = filter.closing.filter.selectedOption
        ? convertToApiCriteria(filter.closing.filter.selectedOption, filter.closing.filter.options)
        : { dateFrom: undefined, dateTo: undefined };
    const ratings = filter.ratings.filter.filter(r => r.selected).map(r => String(r.value));
    const statuses = filter.statuses.filter.filter(f => f.selected).map(f => Number(f.value));
    const managers = filter.managers.filter.filter(m => m.selected).map(m => String(m.value));
    const collateralTypes = filter.collateralType.filter.filter(r => r.selected).map(r => r.value);
    const collateralManagers = filter.managers.filter.filter(c => c.selected).map(c => c.value);
    const selectedCurrencies = filter.currency.filter.filter(c => c.selected).map(c => c.value);
    const currency = selectedCurrencies.length && selectedCurrencies.length === 1 ? selectedCurrencies[0] : undefined;
    const esg = filter.esg.filter.selectedOption;
    const euCompliance = filter.euCompliance.filter.selectedOption;
    const staticDeals = filter.staticDeal.filter.selectedOption;
    const trustees = filter.trustees.filter.filter(c => c.selected).map(c => c.value);
    const amr = filter.amr.filter.selectedOption;
    const outOfNC = filter.outOfNC.filter.selectedOption;
    const outOfRI = filter.outOfRI.filter.selectedOption;

    const nonCallEndYears = getYearsRange(filter.nonCallEnd);
    const reinvestmentEndYears = getYearsRange(filter.reinvestmentEnd);
    const nonCallEndDateRange = selectedDateOptions(filter.nonCallEnd);
    const reinvestmentEndDateRange = selectedDateOptions(filter.reinvestmentEnd);

    const normalizedDateOptions = {
        dateFrom: dateRange?.dateFrom ? dateRange.dateFrom : undefined,
        dateTo: dateRange?.dateTo ? dateRange.dateTo : undefined,
        currentItems: dateRange?.isCurrentInventory
    }
    const normalizedMaturityDateOptions = {
        maturityFrom: maturityDateRange?.dateFrom ?? undefined,
        maturityTo: maturityDateRange?.dateTo ?? undefined
    }
    const normalizedVintageDateOptions = {
        vintageFrom: vintageDateRange?.dateFrom ?? undefined,
        vintageTo: vintageDateRange?.dateTo ?? undefined
    };
    const normalizedClosingDateOptions = {
        closingFrom: closingDateRange?.dateFrom ?? undefined,
        closingTo: closingDateRange?.dateTo ?? undefined
    }

    const requestParams = {
        identifiers: searchSecurities,
        statuses,
        ratings,
        managers,
        collateralTypes,
        collateralManagers,
        nonCallEndFromYear: nonCallEndYears.from,
        nonCallEndToYear: nonCallEndYears.to,
        reinvestmentEndFromYear: reinvestmentEndYears.from,
        reinvestmentEndToYear: reinvestmentEndYears.to,
        nonCallEndFromDate: nonCallEndDateRange.from,
        nonCallEndToDate: nonCallEndDateRange.to,
        reinvestmentEndFromDate: reinvestmentEndDateRange.from,
        reinvestmentEndToDate: reinvestmentEndDateRange.to,
        esg,
        euCompliance,
        staticDeals,
        currency,
        dealers: null,
        outOfNC,
        outOfRI,
        orderByColumn: Number(InventorySecurityOrderByColumn[sortField]) || undefined,
        sortOrder: Number(InventorySecuritySortOrder[sortDirection]) || undefined,
        trustees,
        amr,
        ...normalizedDateOptions,
        ...normalizedMaturityDateOptions,
        ...normalizedVintageDateOptions,
        ...normalizedClosingDateOptions,

    };
    try {
        const file: { blob: Blob, name: string } = yield call(inventoryService.exportInventoryList, requestParams);
        saveAs(file.blob, file.name);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(bdInventoryActions.exportInventoryResponse())
    }
}

function* watchRequestSendEmail(action: { type: string, payload: { selectedDealerEmail: boolean, selectedBloombergEmail: boolean } }) {
    try {
        const params = action.payload;
        yield call(inventoryService.sendEmail, params);
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchAddBloombergEmailRequest(action: { type: string, payload: { email: string } }) {
    try {
        const { email } = action.payload;
        yield call(accountService.saveBloombergEmail, email);
        user.setBloombergEmail(email);
        yield put(bdInventoryActions.toggleVisibleAddBloombergEmailForm());
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(bdInventoryActions.addBloombergEmailResponse());
    }
}

function* watchLogout() {
    yield put(bdInventoryActions.reset());
    yield put(bdInventoryActions.resetEntities());
}

function* watchPushInventory() {
    const isPageActive: boolean = yield select((s: AppState) => !isRequestNone(s.bdInventory.requestStateInventoryExist));

    if (isPageActive) {
        yield put(bdInventoryActions.requestInventoryExistFlag());
    }
}

export function* watchBdInventory() {
    yield takeLatest(getType(bdInventoryActions.init), watchInit);
    yield takeLatest(getType(bdInventoryActions.requestInventoryExistFlag), watchRequestInventoryExist);
    yield takeEvery(getType(bdInventoryActions.loadBdInventory), watchLoadBdInventory);
    yield takeLatest(getType(bdInventoryActions.exportInventoryRequest), watchExportInventory);
    yield takeLatest(getType(bdInventoryActions.requestSendEmail), watchRequestSendEmail);
    yield takeLatest(getType(bdInventoryActions.addBloombergEmailRequest), watchAddBloombergEmailRequest);
    yield takeLatest(
        [
            getType(bdInventoryActions.applyFilter),
            getType(bdInventoryActions.clearFilter)
        ],
        watchRedirectWithFilterArguments
    );
    yield takeEvery(accountActions.LOGOUT, watchLogout);
    yield takeEvery(getType(bdInventoryActions.pushInventory), watchPushInventory);
}
