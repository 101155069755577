import moment from "moment";
import { all, delay, put, select, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { apiOperationActions, errorActions } from "../actions";
import { logger } from "../logging/logger";
import { ApiOperation } from "../types/api-operation/ApiOperation";
import { ApiOperationResult, TOperationResult } from "../types/api-operation/ApiOperationResult";
import { AppState } from "../types/state/AppState";
import { apiUtils } from "../utils/api.utils";
import { isRequesting } from "../utils/request-state.utils";
import { pushNotificationService } from "../services/push-notification.service";
import { user } from "../user/user";
import { jsonUtils } from "../utils/json.utils";

function* watchApiOperationResult(action: TOperationResult) {
    const { result } = action.payload;
    const pending: ApiOperation | undefined = yield select((state: AppState) =>
        state.apiOperation.requests.find(r => r.token === result.token && r.event === result.event));

    logger.trace(`API response token: ${result.token}, status: ${result.statusCode}`);

    if (pending == null) {
        logger.trace(`Api operation result token: ${result.token} event: ${result.event} has no corresponding request`);
        return;
    }

    if (apiUtils.isRequestSuccess(result.statusCode)) {
        yield put(apiOperationActions.success(result));
    } else {
        if ([403, 404].some(e => e === result.statusCode)) {
            yield put(errorActions.criticalError({ status: result.statusCode }));
        } else {
            if (pending.errorToastDisabled || [409, 410].some(e => e === result.statusCode)) {
                yield put(apiOperationActions.error(result));
            } else {
                yield put(apiOperationActions.error(result));
                yield put(errorActions.unexpectedError({
                    status: result.statusCode,
                    message: result.response
                }));
            }
        }
    }

    if (pending.autoRemove) {
        yield put(apiOperationActions.remove(pending.token));
    }
}

function* watchEarlyOperationResult(action: ActionType<typeof apiOperationActions.waitResult>) {
    const responses: ApiOperationResult[] = yield select((s: AppState) => s.apiOperation.responses);

    logger.trace("Waiting for API operation response", action.payload.requests.map(r => r.token))

    yield all(
        responses
            .filter(r => action.payload.requests.some(req => req.token === r.token))
            .map(r => put(apiOperationActions.earlyResult(r)))
    );
}

function* watchStuckOperations() {
    const timeout = 15; // seconds

    logger.trace(`Push fallback: Start looking for stuck async operations every ${timeout} seconds`);

    while (true) {
        if (pushNotificationService.isConnected()) {
            if (!user.isAuthenticated()) {
                logger.trace('Push fallback: Stop looking for stuck async operations')
                break;
            }

            const stuck: ApiOperation[] = yield select((s: AppState) =>
                s.apiOperation.requests.filter(r =>
                    isRequesting(r.state) &&
                    moment().diff(r.createdDate, 'seconds') >= timeout)
            );

            if (stuck.length) {
                logger.trace(`Push fallback: Found ${stuck.length} stuck async operation(s)`, stuck);
                yield resolveStuckOprtations(stuck);
            }
        }

        yield delay(15 * 1000);
    }
}

function* resolveStuckOprtations(operations: ApiOperation[]) {
    try {
        const pushDataCache: string[] = yield pushNotificationService.send("GetPushDataMessages");
        if (!user.isAuthenticated()) return;

        if (!pushDataCache?.length) {
            logger.trace('Push data cache is empty');
            return;
        }

        const operationTokenByResult = new Map<string, ApiOperationResult | undefined>();
        operations.forEach(o => operationTokenByResult.set(o.token, undefined));

        pushDataCache
            .reverse()
            .forEach(x => {
                if ([...operationTokenByResult.values()].some(x => !x)) { // Stop if all stuck operation results are found
                    const jsonData = jsonUtils.tryParse(x);
                    if (
                        jsonData != null &&
                        jsonData.token &&
                        jsonData.statusCode &&
                        operationTokenByResult.has(jsonData?.token)) {
                        const apiOperationResult = jsonData as ApiOperationResult;
                        logger.trace('Push fallback: Found stuck async operation result', apiOperationResult.token);
                        operationTokenByResult.set(apiOperationResult.token, apiOperationResult);
                    }
                }
            });

        const apiOperationTokensWithoutResponse =
            [...operationTokenByResult.entries()]
                .filter(([, response]) => !response)
                .map(([token]) => token);

        if (apiOperationTokensWithoutResponse.length) {
            logger.trace('Push fallback: Cannot find async operation response in push data cache', apiOperationTokensWithoutResponse);
        }

        const stuckApiResponses = [...operationTokenByResult.values()].filter(x => x);
        if (stuckApiResponses.length) {
            yield all(
                stuckApiResponses.map(r => put(apiOperationActions.result(r!)))
            )
        }
    } catch (e) {
        console.log('Push fallback: Failed to handle cached push data', e);
    }
}

export function* watchApiOperation() {
    yield takeEvery([getType(apiOperationActions.result), getType(apiOperationActions.earlyResult)], watchApiOperationResult);
    yield takeEvery(getType(apiOperationActions.waitResult), watchEarlyOperationResult);
    yield takeEvery(getType(apiOperationActions.trackStuckOperations), watchStuckOperations);
}