import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { round } from "lodash";
import { getType } from "typesafe-actions";
import { brokerDealerBiddingActions, bidPlacementActions, pushBwicActions } from "../actions";
import { apiOperationActions } from "../actions/api-operation.actions";
import { bidAsDealerRequestService, biddingService } from "../services";
import { apiUtils, biddingUtils, jsonUtils, numericUtils } from "../utils";
import { Bwic } from "../types/bwic/Bwic";
import { AppState } from "../types/state/AppState";
import { BidsConfirmationState, EditBidState, SubmitBidConfurmationState } from "../types/state/BrokerDealerBiddingState";
import { SubmitBidModel } from '../types/bidding/SubmitBidModel';
import { SubmitBidAttributesModel } from '../types/bidding/SubmitBidAttributesModel';
import { ApiOperationType } from "../types/api-operation/ApiOperationType";
import { RequestState } from "../constants/request-state";
import { ApiOperationResult, TApiCallResponse, TOperationResult } from "../types/api-operation/ApiOperationResult";
import { BidConflict } from "../types/bidding/BidConflict";
import { ActionType } from "../types/ActionType";
import { constants } from "../constants";
import { BwicBase } from "../types/bwic/BwicBase";
import { BwicPosition } from "../types/bwic/BwicPosition";
import { bidConfirmationActions } from "../actions/bid-confirmation.actions";
import { SettlementAgentAgreement } from "../types/bid-as-dealer/SettlementAgentAgreement";
import { user } from "../user";
import { isActiveTrade } from "../types/trades/TradeStatus";
import { BwicProcessType } from "../types/models/Process";

function* watchSubmitBids(action: ActionType<{ positionId?: number, bids: BidsConfirmationState }>) {
    const submitInProgress: boolean =
        yield select((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.submitInProgress);

    if (submitInProgress) return;

    yield put(bidConfirmationActions.submitInProgress(true, action.payload.positionId));

    const bwic: Bwic = yield select((s: AppState) => s.bidding.bwic);
    const { submitResult }: SubmitBidConfurmationState = yield select((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation);
    const securities: BwicPosition[] = yield select((state: AppState) =>
        state.brokerDealerBidding.securities.filter(s =>
            !s.trade &&
            !s.isTradedAway &&
            state.brokerDealerBidding.submitBidsConfirmation.securities?.some(id => id === s.id))
    );

    if (!bwic || !securities || !securities.length) return;

    const targetSecurities = securities.filter(s => action.payload.positionId == null || s.id === action.payload.positionId);

    const bids = targetSecurities
        .filter(s => {
            const { bid, currentBid } = action.payload.bids[s.id];
            return (
                bid.pass ||
                currentBid == null ||
                (bid.value && Number(bid.value) !== Number(currentBid.value)) ||
                (bwic.isAllToAll && bid.commission && numericUtils.numberOrDefault(bid.commission) !== numericUtils.numberOrDefault(currentBid?.commission))
            );
        }).map(s => {
            const { bid, currentBid } = action.payload.bids[s.id];

            const model: SubmitBidModel = {
                positionId: s.id,
                rating: s.rating,
                bidLock: s.bidLock,
                size: +s.size,
                value: Boolean(bid.pass) ? undefined : Number(bid.value ?? currentBid?.value),
                axed: Boolean(bid.axed ?? currentBid?.axed),
                final: Boolean(bid.final ?? currentBid?.final),
                pass: Boolean(bid.pass),
                acceptBiddingOverThemself: Boolean(submitResult[s.id]?.isBidOverThemself),
                stagedBiddingStatus: bwic.process.stagedBiddingStatus
            };

            return addConflictBidLock(model) as SubmitBidModel;
        });

    const bidAttributes = targetSecurities
        .filter(s => {
            const { bid, currentBid } = action.payload.bids[s.id];
            return (
                bid &&
                currentBid &&
                !bids.some(b => b.positionId === s.id) && (
                    Boolean(bid.axed) !== Boolean(currentBid.axed) ||
                    Boolean(bid.final) !== Boolean(currentBid.final)
                )
            );
        }).map(s => {
            const { bid, currentBid } = action.payload.bids[s.id];
            const model: SubmitBidAttributesModel = {
                positionId: s.id,
                bidId: currentBid!.id,
                axed: Boolean(bid.axed ?? currentBid?.axed),
                final: Boolean(bid.final ?? currentBid?.final),
                bidLock: s.bidLock,
                stagedBiddingStatus: bwic.process.stagedBiddingStatus
            };

            return addConflictBidLock(model);
        });

    let submitBidsSuccess = false;
    let submitBidAttributesSuccess = false;

    [submitBidsSuccess, submitBidAttributesSuccess] = yield all([
        bids.length && (
            bwic.isAllToAll
                ? submitBidRequest(bids)
                : resolveApiCall(biddingService.submitBids, ApiOperationType.SubmitBid, bwic.referenceName, bids)
        ),
        bidAttributes.length && resolveApiCall(biddingService.updateBidAttributes, ApiOperationType.SubmitAxedFinal, bidAttributes)
    ]);

    if (bids.length) {
        yield put(bidConfirmationActions.submitResults(
            apiUtils.normalize(
                bids,
                b => b.positionId,
                () => ({ requestState: submitBidsSuccess ? RequestState.request : RequestState.failure })
            )
        ));
    }
    if (bidAttributes.length) {
        yield put(bidConfirmationActions.submitResults(
            apiUtils.normalize(
                bidAttributes,
                b => b.positionId,
                () => ({ requestState: submitBidAttributesSuccess ? RequestState.request : RequestState.failure })
            )
        ));
    }

    yield put(bidConfirmationActions.submitInProgress(false));

    function addConflictBidLock(model: SubmitBidModel | SubmitBidAttributesModel) {
        const conflict = submitResult[model.positionId]?.conflict;
        if (conflict) {
            model.bidLock = conflict.modifiedDate;
        }

        return model;
    }

    function* submitBidRequest(bids: SubmitBidModel[]) {
        const agreement: SettlementAgentAgreement = yield select((s: AppState) => s.entities.settlementAgentAgreements.items[0]);
        if (!agreement) return false;

        const state = action.payload.bids;//[bid.positionId]!.bid;

        const bidRequests = bids.map(b => ({
            positionId: b.positionId,
            brokerDealerId: user.current()!.companyId,
            commission:
                state[b.positionId]?.bid.commission ??
                state[b.positionId]?.currentBid?.commission ??
                agreement.commissions[b.rating],
            value: Number(b.value),
            axed: b.axed,
            final: b.final,
            size: b.size,
            salesCoverage: [],
            bidLock: b.bidLock,
            acceptBiddingOverThemself: b.acceptBiddingOverThemself,
            stagedBiddingStatus: bwic.process.stagedBiddingStatus
        }));

        const result: boolean = yield resolveApiCall(
            bidAsDealerRequestService.submit,
            ApiOperationType.SubmitBidRequest,
            bwic.referenceName,
            bidRequests
        );
        return result;
    }

    function* resolveApiCall(
        func: (...args: any[]) => Promise<TApiCallResponse[]>,
        apiOperationType: ApiOperationType,
        ...args: any[]) {
        try {
            const tokens: TApiCallResponse[] = yield call(func, ...args);

            yield put(apiOperationActions.waitResult(
                ...tokens.map(({ token }) => ({
                    token,
                    event: apiOperationType,
                    state: RequestState.request,
                    autoRemove: true,
                    errorToastDisabled: true,
                    createdDate: new Date()
                }))
            ));

            return true;
        }
        catch {
            return false;
        }
    }
}

function* watchSubmitSuccess(action: TOperationResult) {
    const result = action.payload.result;
    const shouldHandle: boolean = yield shouldHandleApiOperationResult(result);

    if (shouldHandle && result.positionId) {
        yield put(bidConfirmationActions.submitResults({
            [result.positionId]: { requestState: RequestState.success }
        }));
    }
}

function* watchSubmitError(action: TOperationResult) {
    const result = action.payload.result;
    const shouldHandle: boolean = yield shouldHandleApiOperationResult(result);

    if (shouldHandle && result.positionId) {
        yield put(bidConfirmationActions.submitResults({
            [result.positionId]: {
                requestState: RequestState.failure,
                isBidOverThemself: result.statusCode === 300,
                isLoggedOut: result.statusCode === 401,
                stageTransitionConflict: parseOpenBiddingStageTransitionConflict(result),
                conflict: parseConflict(result),
                validationError: parseValidationError(result)
            }
        }));
    }
}

function parseConflict(result: ApiOperationResult) {
    if (hasStatusResponse(409, result)) {
        const conflicts: BidConflict[] | null = jsonUtils.tryParse(result.response);
        return conflicts?.find(c => c.positionId === result.positionId);
    }

    return undefined;
}

function parseOpenBiddingStageTransitionConflict(result: ApiOperationResult) {
    return hasStatusResponse(410, result)
        ? jsonUtils.tryParse(result.response)
        : undefined;
}

function parseValidationError(result: ApiOperationResult) {
    if (hasStatusResponse(400, result) && result.response) {
        return apiUtils.parseErrorMessage(result.response);
    }

    return undefined;
}

function hasStatusResponse(status: number, result: ApiOperationResult) {
    return status === result.statusCode && result.positionId != null && result.response != null;
}

function* shouldHandleApiOperationResult(result: ApiOperationResult) {
    const isOperationApplicable = (
        result.event === ApiOperationType.SubmitBid ||
        result.event === ApiOperationType.SubmitBidRequest ||
        result.event === ApiOperationType.SubmitAxedFinal) &&
        result.positionId != null;

    if (isOperationApplicable) {
        const securities: number[] | undefined =
            yield select((s: AppState) => s.brokerDealerBidding.submitBidsConfirmation.securities);

        return securities && securities.length && securities.some(positionId => positionId === result.positionId);
    }

    return false;
}

function* watchCanSubmitChange(action: { type: string, bwicReferenceName: string, positionId: number }) {
    const bwic: BwicBase | undefined = yield select((s: AppState) => s.bidding.bwic);
    const position: BwicPosition | undefined = yield select((s: AppState) => s.brokerDealerBidding.securities.find(s => s.id === action.positionId));

    if (bwic?.referenceName === action.bwicReferenceName && position) {
        yield put(bidPlacementActions.setBidCanSubmit(position.id));
    }
}

function* watchSetBidCanSubmit(action: ActionType<{ positionId: number }>) {
    const positionId = action.payload.positionId;

    const position: BwicPosition | undefined =
        yield select((s: AppState) => s.brokerDealerBidding.securities.find(s => s.id === positionId));
    const editState: EditBidState | undefined =
        yield select((s: AppState) => s.brokerDealerBidding.editBid[positionId]);
    const isAllToAll: boolean = yield select((s: AppState) => s.bidding.bwic?.isAllToAll);
    const agreement: SettlementAgentAgreement = yield select((s: AppState) => s.entities.settlementAgentAgreements.items[0]);

    if (!position) return;
    if (!editState || (editState.errors && Object.keys(editState.errors).length)) {
        yield put(bidPlacementActions.storeCanSubmitPositionFlag(positionId, false));
        return;
    }

    const isColorDistribution: boolean = yield select((s: AppState) => s.bidding.bwic?.isColorDistribution) ?? false;
    const currentBid = biddingUtils.getCurrentBid(position.bids);
    const currentBidValue = Number(currentBid?.value ?? 0);

    const bidExists = numericUtils.numberOrDefault(editState.value, currentBid?.value ?? 0) > 0 || editState.pass;
    const bidChanged =
        editState.value &&
        numericUtils.numberOrDefault(editState.value) > 0 &&
        +editState.value !== currentBidValue &&
        round((+editState.value - currentBidValue), 4) >= constants.bidMinStep;
    const axedChanged = (editState.axed != null && Boolean(currentBid?.axed) !== Boolean(editState.axed));
    const finalChanged = (editState.final != null && Boolean(currentBid?.final) !== Boolean(editState.final));
    const passChanged = (editState.pass != null && Boolean(currentBid?.pass) !== Boolean(editState.pass));

    let isCommissionValid = true;
    if (isAllToAll) {
        if (editState.commission && !numericUtils.isNumber(editState.commission)) {
            isCommissionValid = false;
        } else {
            const currentCommission = currentBid?.commission ?? agreement?.commissions[position.rating];
            const commission = editState.commission ?? currentCommission;
            const minValue = currentCommission ?? constants.commissionRange.min;
            const maxValue = constants.commissionRange.max;

            isCommissionValid = Number(commission) >= minValue && Number(commission) <= maxValue;
        }
    }

    const canSubmit = bidExists &&
        (bidChanged || axedChanged || finalChanged || passChanged) &&
        isCommissionValid &&
        !position.isTradedAway &&
        !isActiveTrade(position.trade) &&
        !isColorDistribution;

    yield put(bidPlacementActions.storeCanSubmitPositionFlag(positionId, canSubmit));
}

function* watchTrated(action: ReturnType<typeof pushBwicActions.tradeAction>) {
    yield put(bidPlacementActions.revert(action.positionId))
}

function* watchStage2Started(action: ActionType<typeof pushBwicActions.stagedBiddingStartStage2>) {
    const bwic: BwicBase | undefined = yield select((s: AppState) => s.bidding.bwic);

    if (bwic?.process.type === BwicProcessType.BestFootForward) {
        yield put(bidPlacementActions.revertAll());
    }
}

export function* watchBrokerDealerBidding() {
    yield takeEvery(getType(brokerDealerBiddingActions.submitBids), watchSubmitBids);
    yield takeEvery(getType(apiOperationActions.success), watchSubmitSuccess);
    yield takeEvery(getType(apiOperationActions.error), watchSubmitError);
    yield takeEvery(getType(pushBwicActions.newBid), watchCanSubmitChange);
    yield takeEvery(getType(pushBwicActions.axedFinalChange), watchCanSubmitChange);
    yield takeEvery(getType(bidPlacementActions.setBidCanSubmit), watchSetBidCanSubmit);
    yield takeEvery(getType(pushBwicActions.tradeAction), watchTrated)
    yield takeEvery(getType(pushBwicActions.stagedBiddingStartStage2), watchStage2Started);
}
