import { call, put, takeEvery } from "redux-saga/effects";
import { brokerDealerContactListActions } from '../actions/broker-dealer-contact-list.actions';
import { getType } from 'typesafe-actions';
import { contactsService } from '../services';
import { RequestState } from '../constants/request-state';
import { errorActions } from '../actions';
import { accountActions } from '../constants';
import { Contact } from '../types/company/Contact';

function* watchBrokerDealerFetchContacts() {
    try {
        const contacts: Contact[] = yield call(contactsService.getContacts);
        yield put(brokerDealerContactListActions.requestResult(RequestState.success, contacts))
    } catch (e) {
        yield put(brokerDealerContactListActions.requestResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

function* watchLogout() {
    yield put(brokerDealerContactListActions.resetEntities())
}

export function* watchBrokerDealerContactList() {
    yield takeEvery(getType(brokerDealerContactListActions.request), watchBrokerDealerFetchContacts);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
