import { takeEvery, takeLatest, select, call, put, all } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { getType } from 'typesafe-actions';
import {
    blotterHasTradesFailure,
    blotterHasTradesRequest,
    blotterHasTradesSuccess,
    blotterSearchFailure,
    blotterSearchRequest,
    blotterSearchSuccess,
    blotterExportRequest,
    blotterExportSuccess,
    blotterExportFailure,
    blotterInit,
    blotterFiltersReset,
    blotterSetCurrency,
    blotterTogglePending,
    blotterToggleConfirmed,
    blotterToggleRejected,
    blotterToggleFilterBuy,
    blotterToggleFilterSell,
    blotterToggleFilterClient,
    blotterToggleFilterDesc,
    blotterDateFilterChange,
    blotterCustomDateFilterChange,
    blotterSetVconRequest,
    blotterSetVconSuccess,
    blotterSetVconFailure,
    blotterSetBookedRequest,
    blotterSetBookedSuccess,
    blotterSetBookedFailure,
    blotterToggleVcon,
    blotterToggleBooked,
    blotterToggleAllocationStatusSettled,
    blotterToggleAllocationStatusUnsettled,
    blotterToggleAllocationStatusPendingAllocation,
    blotterToggleAllocationStatusPendingSettlement,
    blotterSettlementDateFilterChange,
    blotterSettlementCustomDateFilterChange,
    blotterSetFilterDefaults, blotterToggleAllocationStatusPendingProceeds, blotterSearchRequestNextPage
} from '../actions/blotter.actions';

import { tradingService } from '../services';
import { tradeStatuses } from '../constants/bidding';
import { bwicDateFilterOptions, pushDataActions, roles, routes } from '../constants';
import { RequestState } from '../constants/request-state';
import {
    identifiers,
    multipleCurrencies,
    tradesStatuses,
    tradeSide,
    tradeType,
    dateFrom,
    dateTo,
    settlementStatuses
} from '../utils/filtering/serializers/serializer.definitions';
import { queryStringSerializer } from '../utils/filtering/query-string-serializer';
import { errorActions, searchSecuritiesActions } from '../actions';
import { getDateRangeOption, getSearchDateRange, isRequestSuccess } from '../utils';
import { TradeSide } from '../types/trades/TradeSide';
import { TradeType, tradeTypeTitle } from '../types/trades/TradeType';
import { user } from '../user';
import { tradeSettlementStatusTitle, TradeSettlementStatus } from '../types/settlement/TradeSettlementStatus';
import { companyDetailsActions } from '../actions/company-details.actions';
import { history } from '../history';

function* watchBlotterInit() {
    const qs = history.location.search?.trim();
    if (qs) {
        applyQueryStringFilter(qs);
    } else {
        yield put(blotterSetFilterDefaults());
    }
}

function* applyQueryStringFilter(queryString) {
    yield put(blotterFiltersReset());

    let identifierOptions = [];
    let statusOptions = [];
    let sellementStatusOptions = [];
    let currencyOptions = [];
    let tradeSideOptions = [];
    let tradeTypeOptions = [];
    let from = undefined;
    let to = undefined;
    let settlementFrom;
    let settlementTo;

    const serializers = [
        identifiers(parsedIdentifiers => identifierOptions = parsedIdentifiers),
        tradesStatuses(parsedStatuses => statusOptions = parsedStatuses),
        settlementStatuses(parsedStatuses => sellementStatusOptions = parsedStatuses),
        multipleCurrencies(parsedCurrency => currencyOptions = parsedCurrency),
        tradeSide(parsed => tradeSideOptions = parsed),
        tradeType(parsed => tradeTypeOptions = parsed),
        dateFrom(parsedDate => from = parsedDate),
        dateTo(parsedDate => to = parsedDate),
        dateFrom(parsedDate => settlementFrom = parsedDate, 'settlFrom'),
        dateTo(parsedDate => settlementTo = parsedDate, 'settlTo')
    ];

    queryStringSerializer.deserialize(queryString, serializers);

    yield all(identifierOptions.map(item => put(searchSecuritiesActions.addSearchItem(item))));
    yield all(currencyOptions.map(item => put(blotterSetCurrency(item))));
    yield all(statusOptions.map(statusTitle => {
        if (statusTitle === tradeStatuses.pending.title) return put(blotterTogglePending());
        if (statusTitle === tradeStatuses.affirmed.title) return put(blotterToggleConfirmed());
        if (statusTitle === tradeStatuses.rejected.title) return put(blotterToggleRejected());
        return null;
    }));
    yield all(sellementStatusOptions.map(statusTitle => {
        if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.Unsettled]) return put(blotterToggleAllocationStatusUnsettled());
        if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.PendingSettlement]) return put(blotterToggleAllocationStatusPendingSettlement());
        if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.PendingAllocation]) return put(blotterToggleAllocationStatusPendingAllocation());
        if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.PendingProceeds]) return put(blotterToggleAllocationStatusPendingProceeds());
        if (statusTitle === tradeSettlementStatusTitle[TradeSettlementStatus.Settled]) return put(blotterToggleAllocationStatusSettled());
        return null;
    }));
    yield all(tradeSideOptions.map(side => {
        if (side === TradeSide.Buy) return put(blotterToggleFilterBuy());
        if (side === TradeSide.Sell) return put(blotterToggleFilterSell());
        return null;
    }));
    yield all(tradeTypeOptions.map(side => {
        if (side === tradeTypeTitle[TradeType.Client]) return put(blotterToggleFilterClient());
        if (side === tradeTypeTitle[TradeType.Desk]) return put(blotterToggleFilterDesc());
        return null;
    }));

    // trade date
    const { option, customRange } = getDateRangeOption(from, to);

    yield put(blotterDateFilterChange(option.key === bwicDateFilterOptions.todayAndUpcoming.key ? bwicDateFilterOptions.today : option));

    if (option.key === bwicDateFilterOptions.custom.key) {
        yield put(blotterCustomDateFilterChange(customRange));
    }

    // settl date
    const settlementDateOptions = getDateRangeOption(settlementFrom, settlementTo);

    yield put(blotterSettlementDateFilterChange(
        settlementDateOptions.option.key === bwicDateFilterOptions.todayAndUpcoming.key
            ? bwicDateFilterOptions.today
            : settlementDateOptions.option)
    );

    if (settlementDateOptions.option.key === bwicDateFilterOptions.custom.key) {
        yield put(blotterSettlementCustomDateFilterChange(settlementDateOptions.customRange));
    }
}

function* loadOwnTradesExistenceInfo(ownTrades) {
    if (ownTrades.requestState !== RequestState.request) {
        try {
            yield put(blotterHasTradesRequest());
            const response = yield call(tradingService.doesExist);
            yield put(blotterHasTradesSuccess(JSON.parse(response)));
        } catch (e) {
            yield put(blotterHasTradesFailure(e));
        }
    }
}

function* watchBlotterSearchRequest(action) {
    try {
        const { blotter, searchSecurities } = yield select(state => state);
        const { searchTermItems } = searchSecurities;
        const { filters, orderByColumn, orderByDirection, page, pageSize } = blotter;
        const { ownTrades } = blotter;
        const nextPage = action.payload?.page ?? page;

        const searchParams = {
            ...getSearchParams(searchTermItems, filters),
            orderByColumn,
            sortOrder: orderByDirection,
            page: nextPage,
            pageSize
        };

        const [response] = yield all([
            call(tradingService.search, searchParams),
            call(loadOwnTradesExistenceInfo, ownTrades)
        ]);

        if (!action.payload?.page) { // Left company details panel opened for next page request
            yield put(companyDetailsActions.hide());
        }

        yield put(blotterSearchSuccess(response));

        const pathname = history.location.pathname;
        if (pathname === routes.blotter) {
            searchParams.currency = filters.currency;
            searchParams.side = getSideFilters(filters);
            searchParams.type = getTypeFilter(filters).map(type => tradeTypeTitle[type]);

            yield put(redirectWithFilterArguments(searchParams));
        }
    } catch (e) {
        yield put(errorActions.criticalError(e))
        yield put(blotterSearchFailure(e.message));
    }
}

function redirectWithFilterArguments(searchParams) {
    return () => {
        const serializers = [
            identifiers(),
            tradesStatuses(),
            settlementStatuses(),
            multipleCurrencies(),
            tradeSide(),
            tradeType(),
            dateFrom(),
            dateTo(),
            dateFrom(undefined, 'settlFrom'),
            dateTo(undefined, 'settlTo')
        ];

        searchParams.statuses = searchParams.statuses
            ? searchParams.statuses.map(key => tradeStatuses.getByKey(key).title)
            : [];
        searchParams.settlementStatuses = searchParams.settlementStatuses
            ? searchParams.settlementStatuses.map(s => tradeSettlementStatusTitle[s])
            : [];

        const queryString = queryStringSerializer.serialize(searchParams, serializers);

        if (queryString) {
            history.replace(history.location.pathname + '?' + queryString);
        } else {
            history.replace(history.location.pathname);
        }
    };
}

function getTradeStatusFilter(filters) {
    const statuses = [];
    if (filters.confirmed) statuses.push(tradeStatuses.affirmed.key);
    if (filters.pending) statuses.push(tradeStatuses.pending.key);
    if (filters.rejected) statuses.push(tradeStatuses.rejected.key);

    return statuses;
}

function getSettlementStatusFilter(filters) {
    const statuses = [];

    if (filters.allocationStatus.unsettled) statuses.push(TradeSettlementStatus.Unsettled);
    if (filters.allocationStatus.pendingSettlement) statuses.push(TradeSettlementStatus.PendingSettlement);
    if (filters.allocationStatus.pendingAllocation) statuses.push(TradeSettlementStatus.PendingAllocation);
    if (filters.allocationStatus.pendingProceeds) statuses.push(TradeSettlementStatus.PendingProceeds);
    if (filters.allocationStatus.settled) statuses.push(TradeSettlementStatus.Settled);

    return statuses;
}

function getSideFilters(filters) {
    const side = [];
    if (filters.sellSide) side.push(TradeSide.Sell);
    if (filters.buySide) side.push(TradeSide.Buy);
    return side;
}

function getTypeFilter(filters) {
    const type = [];
    if (filters.client) type.push(TradeType.Client);
    if (filters.desc) type.push(TradeType.Desk);
    return type;
}

function getSearchParams(searchTermItems, filters) {
    const searchParams = {};

    const tradeStatuses = getTradeStatusFilter(filters);
    if (tradeStatuses.length) searchParams.statuses = tradeStatuses;

    const settlementStatuses = getSettlementStatusFilter(filters);
    if (settlementStatuses.length) searchParams.settlementStatuses = settlementStatuses;

    const side = getSideFilters(filters);
    if (side.length === 1) {
        searchParams.side = side[0];
    }

    const type = getTypeFilter(filters);
    if (type.length === 1) {
        searchParams.type = type[0];
    }

    if (searchTermItems.length) {
        searchParams.isinCusipsAndTickers = searchTermItems;
    }

    if (filters.currency.length === 1) {
        searchParams.currency = filters.currency[0];
    }

    const dateRange = getSearchDateRange(filters);
    const settlementDateRange = getSearchDateRange({
        selectedDateOption: filters.settlementSelectedDateOption,
        customDateRange: filters.settlementCustomDateRange
    });

    return {
        ...searchParams,
        ...dateRange,
        settlFrom: settlementDateRange.dateFrom,
        settlTo: settlementDateRange.dateTo
    };
}

function* watchBlotterPushDataTrade(action) {
    const requestStateSecurities = yield select(state => state.blotter.requestStateSecurities);

    if (isRequestSuccess(requestStateSecurities)) {
        const filters = yield select(state => state.blotter.filters);
        const statusFilters = getTradeStatusFilter(filters);
        const isPendingStatusActive = !statusFilters.length || statusFilters.some(s => s === tradeStatuses.pending.key);
        const status = action.trade && action.trade.status;

        if (status === tradeStatuses.pending.key && isPendingStatusActive) {
            yield put(blotterSearchRequest());
        }
    }
}

function* watchBlotterPushDataTradedAway() {
    const requestStateSecurities = yield select(state => state.blotter.requestStateSecurities);

    if (user.hasRoles(...roles.bd()) && isRequestSuccess(requestStateSecurities)) {
        yield put(blotterSearchRequest());
    }
}

function* watchExportRequest() {
    const searchTermItems = yield select(state => state.searchSecurities.searchTermItems);
    const { filters, orderByColumn, orderByDirection } = yield select(state => state.blotter);

    const searchParams = {
        ...getSearchParams(searchTermItems, filters),
        orderByColumn,
        sortOrder: orderByDirection
    };

    try {
        const file = yield call(tradingService.blotterExport, searchParams);
        saveAs(file.blob, file.name);
        yield put(blotterExportSuccess());
    } catch (e) {
        yield put(blotterExportFailure());
    }
}

function* watchBlotterSetVconRequest(action) {
    const { tradeId, isBuyTrade, value } = action.payload;
    try {
        yield put(blotterSetVconRequest(tradeId, isBuyTrade));
        yield call(tradingService.setVcon, action.payload);
        yield put(blotterSetVconSuccess(tradeId, isBuyTrade, value));
    } catch (e) {
        yield put(blotterSetVconFailure(tradeId, isBuyTrade));
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchBlotterSetBookedRequest(action) {
    const { tradeId, isBuyTrade, value } = action.payload;
    try {
        yield put(blotterSetBookedRequest(tradeId, isBuyTrade));
        yield call(tradingService.setBooked, action.payload);
        yield put(blotterSetBookedSuccess(tradeId, isBuyTrade, value));
    } catch (e) {
        yield put(blotterSetBookedFailure(tradeId, isBuyTrade));
        yield put(errorActions.unexpectedError(e));
    }
}

export function* watchBlotter() {
    yield takeLatest(getType(blotterInit), watchBlotterInit);
    yield takeLatest(getType(blotterSearchRequest), watchBlotterSearchRequest);
    yield takeLatest(getType(blotterSearchRequestNextPage), watchBlotterSearchRequest);
    yield takeLatest(getType(blotterExportRequest), watchExportRequest);
    yield takeEvery(pushDataActions.PUSH_DATA_TRADE, watchBlotterPushDataTrade);
    yield takeEvery(pushDataActions.PUSH_DATA_TRADED_AWAY, watchBlotterPushDataTradedAway);
    yield takeEvery(blotterToggleVcon, watchBlotterSetVconRequest);
    yield takeEvery(blotterToggleBooked, watchBlotterSetBookedRequest);
}
