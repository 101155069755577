import { ActionType, getType } from 'typesafe-actions';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { shouldRequest } from "../utils";
import { adminInventoryEditActions } from '../actions/admin-inventory-edit.actions';
import { errorActions } from '../actions';
import { inventoryService } from "../services/inventory.service";
import { AppState } from '../types/state/AppState';
import { RequestState } from '../constants/request-state';
import { InventoryPosition } from '../types/inventory/InventoryPosition';
import { accountActions } from '../constants';
import { companiesService } from '../services';
import { CompanySlim } from '../types/company/CompanySlim';

function* watchLoadBrokerDealerInventory(action: ActionType<typeof adminInventoryEditActions.loadInventory>) {
    const requestState: RequestState = yield select((state: AppState) => state.entities.adminInventoryEdit.requestState);

    if (shouldRequest(requestState)) {
        yield put(adminInventoryEditActions.requestInventory());
        let company: CompanySlim | undefined;
        try {
            const [response, company]: [
                response: { inventorySecurities: InventoryPosition[]; lockModifiedDate?: Date; },
                company: CompanySlim] =
            yield all([
                call(inventoryService.getAdminEditInventory, action.payload.companyId),
                call(companiesService.getCompany, undefined, action.payload.companyId)
            ]);

            yield put(adminInventoryEditActions.requestInventorySuccess(
                company,
                response.inventorySecurities,
                response.lockModifiedDate)
            );
        } catch (e) {
            if (e.status === 404) {
                yield put(adminInventoryEditActions.requestInventorySuccess(company, []));
            } else {
                yield put(adminInventoryEditActions.requestInventoryError());
                yield put(errorActions.criticalError(e));
            }
        }
    }

}

function* watchLogout() {
    yield put(adminInventoryEditActions.reset());
}

export function* watchAdminInventoryEdit() {
    yield takeEvery(getType(adminInventoryEditActions.loadInventory), watchLoadBrokerDealerInventory);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
