import { call, put, select, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { apiOperationActions, bidOnBehalfActions, errorActions, notificationActions } from "../actions";
import { AppState } from "../types/state/AppState";
import { BidOnBehalfState, EditBidOnBehalfState, getEditKey, parseKey } from "../types/state/BidOnBehalfState";
import { bidOnBehalfService } from '../services';
import { BwicPosition } from "../types/bwic/BwicPosition";
import { BulkSaveBidOnBehalf, SaveBidOnBehalf } from '../types/bid-on-behalf/BulkSaveBidOnBehalf';
import { arrayUtils, bidOnBehalfUtils, formatUtils, jsonUtils } from "../utils";
import { BidApiOperationResult, TOperationResult } from "../types/api-operation/ApiOperationResult";
import { ApiOperationType } from "../types/api-operation/ApiOperationType";
import { Bid } from "../types/bidding/Bid";
import { BidOnBehalfConflict } from "../types/bid-on-behalf/BidOnBehalfConflict";
import { RequestState } from "../constants/request-state";
import { CompanySlim } from "../types/company/CompanySlim";
import { accountActions, pushDataActions } from "../constants";
import { CompanyHiddenState } from "../types/state/DealerListPanelState";
import { FavoriteCompanyState } from "../types/state/FavoriteBrokerDealersState";
import { getSellerBiddingCompanies } from "../selectors";
import { TradeStatus } from "../types/trades/TradeStatus";
import { CompanyStatus } from "../types/company/CompanyStatus";
import { SettlementAgentAgreement } from "../types/bid-as-dealer/SettlementAgentAgreement";
import { Bwic } from "../types/bwic/Bwic";
import { BwicProcessType } from "../types/models/Process";

function* watchBidOnBehalfCompaniesRequest() {
    const state: BidOnBehalfState = yield select((state: AppState) => state.bidOnBehalf);
    const bwic: Bwic = yield select((state: AppState) => state.bidding.bwic);

    if (state.isRequesting || !bwic) {
        return;
    }

    yield put(bidOnBehalfActions.setCompainesRequesting(true));

    try {
        const companies: CompanySlim[] = yield call(bidOnBehalfService.getCompanies, bwic.referenceName);
        companies.sort((a, b) => a.name.localeCompare(b.name));
        yield put(bidOnBehalfActions.storeCompanies(companies));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(bidOnBehalfActions.setCompainesRequesting(false));
    }
}

function* watchOperationError(action: TOperationResult) {
    const { result } = action.payload;
    if (result.event === ApiOperationType.submitBidsOnBehalf) {
        if (result.statusCode === 409) {
            const conflicts: BidOnBehalfConflict[] | undefined = result.response && jsonUtils.tryParse(result.response);
            if (conflicts != null && conflicts.length) {
                yield put(bidOnBehalfActions.storeConflicts(conflicts));
                yield put(bidOnBehalfActions.conflictsPopupVisible(true));
            }
        } else if (result.statusCode === 410 && result.positionId) {
            const bidSumitResult = result as BidApiOperationResult;
            if (bidSumitResult.companyId) {
                const company: CompanySlim | undefined = yield select((s: AppState) => s.bidOnBehalf.companies.find(c => c.id === bidSumitResult.companyId));
                const editingBid: EditBidOnBehalfState | undefined =
                    yield select((s: AppState) => s.bidOnBehalf.editState[getEditKey(result.positionId!, bidSumitResult.companyId!)])
                if (company && editingBid) {

                    yield put(bidOnBehalfActions.resetBidEdit(result.positionId, bidSumitResult.companyId));
                    const bid = editingBid.pass ? 'PASS' : formatUtils.formatBid(Number(editingBid.value));
                    yield put(notificationActions.notificationAddErrorMessage(`Bid ${bid} can’t be saved for ${company.code}`));
                }
            }
        }
    }
}

type TSaveAction = { type: string, payload: { resolveConflicts?: boolean } }
function* watchBidOnBehalfBulkSave(action: TSaveAction) {
    const isSubmitting: boolean = yield select((state: AppState) => state.bidOnBehalf.isSubmitting);
    if (isSubmitting) return;

    const bwicReferenceName: string = yield select((state: AppState) => state.bidding.bwic && state.bidding.bwic.referenceName);
    if (!bwicReferenceName) return;

    const isConflictsPopupVisible: boolean = yield select((state: AppState) => state.bidOnBehalf.isConflictsPpopupVisible);
    if (isConflictsPopupVisible && !action.payload.resolveConflicts) return; // Stop auto-save when conflicts popup is shown

    const waitingResult: boolean = yield select((state: AppState) =>
        state.apiOperation.requests.some(r =>
            r.event === ApiOperationType.submitBidsOnBehalf &&
            r.state === RequestState.request
        )
    );

    const bwic: Bwic = yield select((s: AppState) => s.bidding.bwic);
    const positions: BwicPosition[] = yield select((state: AppState) => state.sellerBidding.securities);

    if (waitingResult || !bwic || !positions?.length) return;
    
    const editState: { [key: string]: EditBidOnBehalfState } = yield select((state: AppState) => state.bidOnBehalf.editState);
    const bidsByCompany = new Map<number, SaveBidOnBehalf[]>();
    const bids = getBidsToSubmit(positions, editState);
    const conflicts: BidOnBehalfConflict[] = action.payload.resolveConflicts
        ? yield select((state: AppState) => state.bidOnBehalf.conflicts)
        : [];

    bids.forEach(({ positionId, companyId, bid }) => {
        const position = positions.find(p => p.id === positionId);
        if (position) {
            if (!bidsByCompany.has(companyId)) {
                bidsByCompany.set(companyId, []);
            }

            const conflictBidLock = conflicts.find(c => c.positionId === position.id && c.companyId === companyId)?.modifiedDate
            const companyBidLock = position.bidLocks?.find(b => b.companyId === companyId)?.bidLock;
            const bidLock = conflictBidLock && companyBidLock
                ? arrayUtils.max([conflictBidLock, companyBidLock], date => new Date(date).getTime())
                : conflictBidLock ?? companyBidLock;

            const bids = position.bids || []
            const companyBids = bids.filter(b => b.company.id === companyId);
            const latestBid: Bid | undefined = arrayUtils.max(companyBids, bid => bid.id);

            bidsByCompany
                .get(companyId)
                ?.push({
                    positionId,
                    bidLock,
                    size: position.size,
                    value: bid.value ? +bid.value : latestBid?.value,
                    axed: bid.axed == null ? !!latestBid?.axed : bid.axed,
                    final: bid.final == null ? !!latestBid?.final : bid.final,
                    pass: !!bid.pass,
                    stagedBiddingStatus: bwic.process.stagedBiddingStatus
                });
        }
    });

    const bulkList: BulkSaveBidOnBehalf[] = [...bidsByCompany.entries()]
        .map(([companyId, bids]) => ({
            brokerDealerCompanyId: companyId,
            bids
        }));


    if (!bulkList.length) return;

    if (action.payload.resolveConflicts) {
        yield put(bidOnBehalfActions.conflictsPopupVisible(false));
    }

    yield put(bidOnBehalfActions.submitProgress(true));

    try {
        const tokens: string[] = yield call(bidOnBehalfService.submitBidsBulk, bwicReferenceName, bulkList);
        if (tokens && tokens.length) {
            yield put(apiOperationActions.waitResult(
                ...tokens.map(token => ({
                    token,
                    event: ApiOperationType.submitBidsOnBehalf,
                    state: RequestState.request,
                    autoRemove: true,
                    createdDate: new Date()
                }))
            ));
        }
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(bidOnBehalfActions.submitProgress(false));
    }
}

function getBidsToSubmit(
    positions: BwicPosition[],
    editState: { [key: string]: EditBidOnBehalfState }) {

    if (!positions || !positions.length) return [];

    return Object
        .keys(editState)
        .filter((key: string) => {
            const { positionId, companyId } = parseKey(key);
            const edit = editState[key];

            if (!edit.error && !edit.editing) {
                const position = positions.find(s => s.id === positionId);
                if (position) {
                    const companyBids = position.bids?.filter(b => b.company.id === companyId) ?? [];
                    const latestBid: Bid | undefined = arrayUtils.max(companyBids, bid => bid.id);

                    return bidOnBehalfUtils.isBidChanged(edit, latestBid);
                }
            }

            return false;
        })
        .map((key: string) => {
            const { positionId, companyId } = parseKey(key);

            return {
                positionId,
                companyId,
                bid: editState[key]
            };
        });
}

type TNewBidAction = { type: string, bwicReferenceName: string, positionId: number, bid: Bid };
function* watchNewBid(action: TNewBidAction) {
    const bwicReferenceName: string = yield select((state: AppState) => state.bidding.bwic && state.bidding.bwic.referenceName);
    if (!bwicReferenceName ||
        !action.bwicReferenceName ||
        !action.positionId ||
        !action.bid ||
        bwicReferenceName !== action.bwicReferenceName) return;

    const edit: EditBidOnBehalfState | undefined =
        yield select((state: AppState) => state.bidOnBehalf.editState[getEditKey(action.positionId, action.bid.company.id)]);

    if (edit && !bidOnBehalfUtils.isBidChanged(edit, action.bid)) {
        yield put(bidOnBehalfActions.resetBidEdit(action.positionId, action.bid.company.id));
    }
}


function* watchMoveNextBid(action: { type: string, payload: { companyId: number, positionId: number } }) {
    const securities: BwicPosition[] = yield select((state: AppState) => state.sellerBidding.securities);
    const participants: CompanySlim[] = yield select((state: AppState) => state.bidding.bwic.companies);
    const processType: BwicProcessType = yield select((state: AppState) => state.bidding.bwic.process?.type) ?? BwicProcessType.Standard;
    const offPlatformCompanies: CompanySlim[] = yield select((state: AppState) => state.bidOnBehalf.companies);
    const hiddenState: CompanyHiddenState = yield select((state: AppState) => state.dealerListPanel.companyHiddenState);
    const favorites: FavoriteCompanyState = yield select((state: AppState) => state.favoriteBrokerDealers.favorites);
    const agreements: SettlementAgentAgreement[] = yield select((state: AppState) => state.entities.settlementAgentAgreements.items);
    const companies = getSellerBiddingCompanies({ participants, offPlatformCompanies, hiddenState, favorites, processType, agreements, securities });

    if (!companies.length) return;

    const isTraded = (p: BwicPosition) =>
        p.trade != null &&
        (p.trade.status === TradeStatus.pending || p.trade.status === TradeStatus.affirmed);

    const isOnPlatform = (c: CompanySlim) => c.status === CompanyStatus.active;

    const currentPositionIndex = securities.findIndex(p => p.id === action.payload.positionId);
    if (currentPositionIndex < 0) return;
    let nextPositionId: number | undefined = securities.find((p, i) => !isTraded(p) && i > currentPositionIndex)?.id;

    let nextCompanyId: number | undefined = action.payload.companyId;
    if (!nextPositionId) { //last position is focused move to the next company
        const currentCompanyIndex = companies.findIndex(c => c.id === action.payload.companyId);
        if (currentCompanyIndex < 0) return;

        nextCompanyId = companies.find((c, i) => !isOnPlatform(c) && i > currentCompanyIndex)?.id;
        if (nextCompanyId == null) nextCompanyId = companies.find(c => !isOnPlatform(c))?.id;
    }

    if (!nextPositionId) {
        // move to the first not traded position
        nextPositionId = securities.find(p => !isTraded(p))?.id;
    }

    if (nextPositionId && nextCompanyId) {
        yield put(bidOnBehalfActions.setNextBidIdentifiers(nextCompanyId, nextPositionId));
    }
}

function* watchLogout() {
    yield put(bidOnBehalfActions.reset())
}

export function* watchBidOnBehalf() {
    yield takeEvery(getType(bidOnBehalfActions.requestCompanies), watchBidOnBehalfCompaniesRequest);
    yield takeEvery(getType(bidOnBehalfActions.submit), watchBidOnBehalfBulkSave);
    yield takeEvery(getType(apiOperationActions.error), watchOperationError);
    yield takeEvery(pushDataActions.PUSH_DATA_NEW_BID, watchNewBid);
    yield takeEvery(getType(bidOnBehalfActions.moveNextBid), watchMoveNextBid);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
