import { call, put, takeEvery } from "redux-saga/effects";
import { getType } from 'typesafe-actions';
import { usersService } from '../services';
import { RequestState } from '../constants/request-state';
import { errorActions } from '../actions';
import { accountActions } from '../constants';
import { brokerDealerUserListActions } from '../actions/broker-dealer-user-list.actions';
import { UserSlim } from '../types/account/UserSlim';

function* watchBrokerDealerFetchContacts() {
    try {
        const contacts: UserSlim[] = yield call(usersService.getActiveBrokerDealers);
        yield put(brokerDealerUserListActions.requestResult(RequestState.success, contacts))
    } catch (e) {
        yield put(brokerDealerUserListActions.requestResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

function* watchLogout() {
    yield put(brokerDealerUserListActions.resetEntities())
}

export function* watchBrokerDealerUserList() {
    yield takeEvery(getType(brokerDealerUserListActions.request), watchBrokerDealerFetchContacts);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
