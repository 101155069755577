import { getType } from 'typesafe-actions';
import { put, takeLatest, call } from 'redux-saga/effects';
import { amrPipelineDetailedActions, amrTransactionHistoryActions, errorActions } from '../actions';
import { ActionType } from '../types/ActionType';
import { amrPipelineService } from '../services/amr-pipeline.service';
import Differ from '../utils/differ';
import { Difference, DifferenceType, Traverse } from '../utils/differ/types';
import { HistoryItem, OriginatingTransactionHistory } from '../types/amr-pipeline/models/OriginatingTransactionHistory';
import { PipelineHistoryItem } from '../types/state/PipelineHistoryState';
import { OriginatingTransactionDiff } from '../types/amr-pipeline/models/OriginatingTransactionDiff';
import { dealStructureUtils } from '../utils/deal-structure.utils';
import { OriginatingTransaction } from '../types/amr-pipeline/models/OriginatingTransaction';
import moment from 'moment';
import { OriginatingTransactionClass } from '../types/amr-pipeline/models/OriginatingTransactionClass';
import { amrFormatUtils } from '../utils';
import { OriginatingTransactionClassDiff } from '../types/amr-pipeline/models/OriginatingTransactionClassDiff';
import { RequestState } from '../constants/request-state';
import { flatten, isNil, uniqBy } from 'lodash';
import { Contact } from '../types/amr-pipeline/models/Contact';

interface OriginatingTransactionClassFieldsDiff extends HistoryItem {
    classes: OriginatingTransactionClass[];
}

interface TransactionHistoryAction {
    dealReferenceName: string;
    transactionReferenceName: string;
}

interface TransactionHistoryDetailsAction extends TransactionHistoryAction {
    version: number;
    companyReferenceName: string;
}

interface FieldsUpdatedHistoryAction extends TransactionHistoryAction {
    classReferenceName?: string;
}

interface LastUpdatedFields {
    guidance?: Date;
    subscription?: Date;
}

const byEventDateTime = (a: HistoryItem, b: HistoryItem) =>
    moment(a.eventDateTime).unix() - moment(b.eventDateTime).unix();

const originatingTransactionDiffer = Differ<OriginatingTransactionDiff>({
    // Exclude syndicate contacts from check
    syndicateContacts: {
        traverse: Traverse.Skip,
    },
    classes: {
        iteratee: (x) => x.referenceName,
    },
    // Portfolio is in imprecise section
    portfolio: {
        iteratee: (x) => x.issuer,
        traverse: Traverse.Shallow,
    },
    documents: {
        iteratee: (x) => x.referenceName,
    },
    collateralQualityTests: {
        iteratee: (x) => x.value,
    },
});

const originatingTransactionClassFieldsDiffer = Differ<OriginatingTransactionClassFieldsDiff>({
    classes: {
        iteratee: (x) => x.referenceName,
    },
});

const formatRating = (transactionClass: OriginatingTransactionClass) => {
    if (isNil(transactionClass.expectedRatingMoodys) &&
        isNil(transactionClass.expectedRatingSnP) &&
        isNil(transactionClass.expectedRatingFitch) &&
        isNil(transactionClass.expectedRatingKbra) &&
        isNil(transactionClass.expectedRatingDbrs)
    ) {
        return undefined;
    }

    return amrFormatUtils.formatRating(transactionClass);
};

const countZeroAsNull = <T>(fn: (arg: T) => number) => (arg: T): number | undefined => {
    const calculatedValue = fn(arg);

    return calculatedValue
        ? calculatedValue
        : undefined;
};

const classWithCalculatedFields = (transactionClass: OriginatingTransactionClass) => {
    return {
        ...transactionClass,
        coupon: amrFormatUtils.formatInventoryCoupon(transactionClass.floaterIndex, transactionClass.margin, ''),
        ratingString: formatRating(transactionClass),
        priceNumber: isNil(transactionClass.price) ? transactionClass.price : parseFloat(transactionClass.price),
    } as OriginatingTransactionClassDiff;
};

const withCalculatedFields = (transaction?: OriginatingTransaction) => {
    if (!transaction) {
        return undefined;
    }

    return {
        ...transaction,
        calculatedTargetPar: countZeroAsNull(dealStructureUtils.calcTargetPar)(transaction),
        calculatedBidPrice: countZeroAsNull(dealStructureUtils.calcBidPrice)(transaction),
        calculatedAskPrice: countZeroAsNull(dealStructureUtils.calcAskPrice)(transaction),
        classes: transaction.classes.map(classWithCalculatedFields),
    } as OriginatingTransactionDiff;
};

function* watchFetchTransactionHistory(action: ActionType<TransactionHistoryAction>) {
    const { dealReferenceName, transactionReferenceName } = action.payload;

    try {
        const transactions: OriginatingTransactionHistory[] = yield call(amrPipelineService.getTransactionHistory, dealReferenceName, transactionReferenceName);

        const transactionsHistory = transactions.sort(byEventDateTime).map((item, index, self) => {
            const previousTransaction = withCalculatedFields(self[index - 1]);
            const currentTransaction = withCalculatedFields(item);

            // Do not check first version to avoid showing changes for newly created transaction
            const diff: Difference<OriginatingTransactionDiff> = item.version > 0
                ? originatingTransactionDiffer(previousTransaction, currentTransaction)
                : {};

            return {
                version: item.version,
                versionTitle: `${item.version === self.length ? 'Curr.: ' : ''}V.${item.version}`,
                versionTitleFull: `${item.version === self.length ? 'Current: ' : ''}V.${item.version}`,
                eventDateTime: item.eventDateTime,
                transaction: {
                    ...currentTransaction,
                    syndicateContacts: [],
                    isDetailsLoaded: false,
                },
                diff,
            } as PipelineHistoryItem;
        }).reverse();

        const allHistoryClassesList = uniqBy(
            flatten(transactionsHistory.map(item => item.transaction.classes)), 'name'
        ).map(c => {
            const rating = formatRating(c);
            return {
                text: `Class ${c.name} ${rating ? `(${rating})` : ''}`,
                searchTerm: `Class ${c.name}`,
                synonyms: [
                    `Tranche ${c.name}`,
                    c.rating || '',
                    c.expectedRatingSnP || '',
                    c.expectedRatingDbrs || '',
                    c.expectedRatingFitch || '',
                    c.expectedRatingKbra || '',
                    c.expectedRatingMoodys || '',
                    c.expectedRatingSnP || '',
                ],
            };
        });

        yield put(amrTransactionHistoryActions.transactionHistoryResponse(RequestState.success, transactionsHistory, allHistoryClassesList));
    } catch (e) {
        yield put(amrTransactionHistoryActions.transactionHistoryResponse(RequestState.failure));
    }
}

function* watchLoadTransactionDetails(action: ActionType<TransactionHistoryDetailsAction>) {
    const {
        version,
        dealReferenceName,
        transactionReferenceName,
        companyReferenceName,
    } = action.payload;

    try {
        yield put(amrPipelineDetailedActions.transactionDetailsRequesting(true));

        if (!companyReferenceName) {
            yield put(amrTransactionHistoryActions.storeTransactionDetais(version, { syndicateContacts: [] }));
            return;
        }

        const syndicateContacts: Contact[] = yield call(
            amrPipelineService.getSyndicateContactsByArranger,
            dealReferenceName,
            transactionReferenceName,
            companyReferenceName
        );

        yield put(amrTransactionHistoryActions.storeTransactionDetais(version, { syndicateContacts }));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(amrPipelineDetailedActions.transactionDetailsRequesting(false));
    }
}

function* watchTransactionFieldsUpdated(action: ActionType<FieldsUpdatedHistoryAction>) {
    const { dealReferenceName, transactionReferenceName, classReferenceName } = action.payload;

    try {
        const transactions: OriginatingTransactionClassFieldsDiff[] = yield call(
            amrPipelineService.getTransactionHistory,
            dealReferenceName,
            transactionReferenceName,
            { classesOnly: true }
        );

        const history = transactions.sort(byEventDateTime).map((item, index, self) => {
            const difference = originatingTransactionClassFieldsDiffer(
                self[index - 1],
                item
            );

            return {
                difference,
                eventDateTime: item.eventDateTime,
            };
        });

        const fieldsLastUpdated = history.reduce((acc: LastUpdatedFields, next) => {
            const { guidance, subscription } = acc;
            const { difference, eventDateTime } = next;

            if (!difference.classes) {
                return acc;
            }

            const classWithChangedGuidance = difference.classes.find(({ difference }) => {
                const specificClassCondition = classReferenceName
                    ? difference?.referenceName?.derivedValue === classReferenceName
                    : true;

                return specificClassCondition && difference?.guidance?.type !== DifferenceType.Unchanged;
            });

            const classWithChangedSubscription = difference.classes.find(({ difference }) => {
                const specificClassCondition = classReferenceName
                    ? difference?.referenceName?.derivedValue === classReferenceName
                    : true;

                return specificClassCondition && difference?.subscription?.type !== DifferenceType.Unchanged;
            });

            return {
                guidance: classWithChangedGuidance ? eventDateTime : guidance,
                subscription: classWithChangedSubscription ? eventDateTime : subscription,
            };
        }, { });

        yield put(amrTransactionHistoryActions.transactionFieldsUpdatedResponse(
            RequestState.success,
            fieldsLastUpdated
        ));
    } catch (e) {
        yield put(amrTransactionHistoryActions.transactionFieldsUpdatedResponse(RequestState.failure));
    }
}

export function* watchTransactionHistory() {
    yield takeLatest(getType(amrTransactionHistoryActions.transactionHistoryRequest), watchFetchTransactionHistory);
    yield takeLatest(getType(amrTransactionHistoryActions.loadTransactionDetais), watchLoadTransactionDetails);
    yield takeLatest(getType(amrTransactionHistoryActions.transactionFieldsUpdatedRequest), watchTransactionFieldsUpdated);
}
