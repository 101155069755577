import { getType } from 'typesafe-actions';
import { put, takeLatest, call } from 'redux-saga/effects';
import { amrPipelineCommonActions, errorActions } from '../actions';
import { AnyAction } from 'redux';
import { companiesService } from '../services';
import { Company } from '../types/amr-pipeline/models/Company';
import { amrPipelineService } from '../services/amr-pipeline.service';
import { routes } from '../constants';
import { user } from '../user';
import { TransactionResponse } from '../types/amr-pipeline/models/TransactionResponse';
import { history } from '../history';

function* watchLoadUserCompany(action: AnyAction) {
    try {
        const company: Company = yield call(companiesService.getCurrentUserCompany, true);

        yield put(amrPipelineCommonActions.loadUserCompanyResponse(company));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* registerUser() {
    try {
        const company: Company = yield call(companiesService.getCurrentUserCompany, true);

        yield call(amrPipelineService.registerUser, user, company);
        yield put(amrPipelineCommonActions.loadUserCompanyResponse(company));

    } catch (e) {
        yield put(errorActions.unexpectedError(e));
        yield put(amrPipelineCommonActions.loadUserCompanyError())
    }
}

function* watchRedirectInvitedClientToTransactionDetails() {
    try {
        const transactions: TransactionResponse = yield call(amrPipelineService.getTransactionsList, { count: 1 });
        const transaction = transactions.items[0];

        if (transaction) {
            yield call(history.push, routes.transactionDetailUrl(transaction.referenceName, transaction.dealReferenceName));
        } else {
            yield call(history.push, routes.issuanceMonitorWithNoData);
        }
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

export function* watchAmrPipelineCommon() {
    yield takeLatest(getType(amrPipelineCommonActions.loadUserCompanyRequest), watchLoadUserCompany);
    yield takeLatest(getType(amrPipelineCommonActions.registerUser), registerUser);
    yield takeLatest(
        getType(amrPipelineCommonActions.redirectInvitedClientToTransactionDetails),
        watchRedirectInvitedClientToTransactionDetails,
    );
}
