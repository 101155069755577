import { takeLatest, call, put } from 'redux-saga/effects';
import { ActionType, getType } from 'typesafe-actions';
import { saveAs } from 'file-saver';
import { bwicService, dashboardService } from '../services';
import { bwicLogActions } from '../actions/bwic-log.actions';
import { SecurityBwicStatisticsSummary } from '../types/security/SecurityBwicStatisticsSummary';

function* watchBwicLogInit(action: ActionType<typeof bwicLogActions.bwicLogInitRequest>) {
    try {
        const { security } = action.payload;
        const statistics: SecurityBwicStatisticsSummary = yield call(bwicService.getSecurityBwicStatistics, security.securityId);

        yield put(bwicLogActions.bwicLogInitSuccess(statistics));
    } catch (e) {
        yield put(bwicLogActions.bwicLogInitFailure(e));
    }
}

function* watchBwicLogExport(action: ActionType<typeof bwicLogActions.bwicLogExportDataRequest>) {
    try {
        const { securityId } = action.payload;
        const { blob, name } = yield call(dashboardService.exportBwicStatistic, securityId);
        saveAs(blob, name);
        yield put(bwicLogActions.bwicLogExportDataSuccess())
    } catch (e) {
        yield put(bwicLogActions.bwicLogExportDataFailure(e))
    }
}

export function* watchBwicLog() {
    yield takeLatest(getType(bwicLogActions.bwicLogInitRequest), watchBwicLogInit);
    yield takeLatest(getType(bwicLogActions.bwicLogExportDataRequest), watchBwicLogExport);
}
