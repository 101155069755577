import { call, put, takeEvery } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { errorActions } from "../actions";
import { assetManagerActions } from '../actions/asset-manager.actions';
import { RequestState } from "../constants/request-state";
import { companiesService } from '../services/companies.service';
import { CompanySlim } from "../types/company/CompanySlim";

function* watchRequest() {
    try {
        const accounts: CompanySlim[] = yield call(companiesService.getActiveAssetManagers);
        yield put(assetManagerActions.requestResult(
            RequestState.success,
            accounts.sort((a, b) => a.name.localeCompare(b.name)))
        );
    } catch (e) {
        yield put(errorActions.criticalError(e));
        yield put(assetManagerActions.requestResult(RequestState.failure));
    }
}

export function* watchAssetManager() {
    yield takeEvery(getType(assetManagerActions.request), watchRequest);
}
