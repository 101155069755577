import { getType } from "typesafe-actions";
import { takeEvery, call, put } from "redux-saga/effects";
import { BrokerDealerCompanySlim } from "../types/company/BrokerDealerCompanySlim";
import { brokerDealerListActions } from "../actions/broker-dealer-list.actions";
import { accountActions } from "../constants";
import { companiesService } from "../services";
import { errorActions } from "../actions";
import { RequestState } from "../constants/request-state";

function* watchRequestBrokerDealers() {
    try {
        const brokerDealers: BrokerDealerCompanySlim[] = yield call(companiesService.getBrokerDealers);
        yield put(brokerDealerListActions.requestResult(RequestState.success, brokerDealers));
    } catch (e) {
        yield put(brokerDealerListActions.requestResult(RequestState.failure));
        yield put(errorActions.criticalError(e));
    }
}

function* watchLogout() {
    yield put(brokerDealerListActions.resetEntities());
}

export function* watchBrokerDealerList() {
    yield takeEvery(getType(brokerDealerListActions.request), watchRequestBrokerDealers);
    yield takeEvery(accountActions.LOGOUT, watchLogout);
}
