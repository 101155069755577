import { call, put, select, takeLatest } from "redux-saga/effects";
import { getType } from "typesafe-actions";
import { addToPortfolioActions } from "../actions/add-to-portfolio.actions";
import { portfolioService } from "../services";
import { errorActions } from "../actions";
import { AppState } from "../types/state/AppState";
import { ApiError } from "../types/ApiError";
import { BwicPositionBase } from "../types/bwic/BwicPositionBase";
import { jsonUtils } from "../utils";
import { portfolioListActions } from "../actions/portfolio-list.actions";
import { Portfolio } from "../types/portfolio/Portfolio";

function* watchAddSecurityToPortfolio(action: { type: string, payload: { position: BwicPositionBase, size: Number, newPortfolioName?: string } }) {
    const portfolio: Portfolio | undefined = yield select((s: AppState) => s.addToPortfolio.selectedPortfolio);

    const { securityId, isinCusip, ticker, rating } = action.payload.position;

    const securityModel = {
        securityId,
        isinCusip,
        ticker,
        rating,
        size: action.payload.size
    };

    const { newPortfolioName } = action.payload

    try {
        yield put(addToPortfolioActions.setSubmittingProgress(true));
        if (newPortfolioName) {
            yield call(portfolioService.createNewPortfolio, newPortfolioName, [securityModel], true);
        } else {
            yield call(portfolioService.saveSecurityPosition, portfolio?.id, securityModel);
        }
        yield put(addToPortfolioActions.reset());
        yield put(portfolioListActions.reset());
    } catch (e) {
        const error: ApiError | null = jsonUtils.tryParse(e.message);

        if (error) {
            yield put(addToPortfolioActions.storeError(error));
        } else {
            yield put(errorActions.unexpectedError(e));
        }
    }
    finally {
        yield put(addToPortfolioActions.setSubmittingProgress(false));
    }
}

export function* watchAddToPortfolio() {
    yield takeLatest(getType(addToPortfolioActions.addSecurityToPortfolio), watchAddSecurityToPortfolio);
}
