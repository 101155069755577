import { ActionType, getType } from 'typesafe-actions';
import { put, takeLatest, call } from 'redux-saga/effects';
import { errorActions } from '../actions';
import { addressActions } from '../actions/address.actions';
import { addressService } from '../services/address.service';
import { Country } from '../types/location/Country';
import { State } from '../types/location/State';
import { City } from '../types/location/City';

function* watchLoadCountries() {
    try {
        const countries: Country[] = yield call(addressService.getCountries);

        yield put(addressActions.loadCountriesResponse(countries));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchLoadStates(action: ActionType<typeof addressActions.loadStates>) {
    try {
        const { countryId } = action.payload;

        const states: State[] = yield call(addressService.getStates, countryId);

        yield put(addressActions.loadStatesResponse(states));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

function* watchLoadCities(action: ActionType<typeof addressActions.loadCities>) {
    try {
        const { countryId, stateId } = action.payload;

        const cities: City[] = yield call(addressService.getCities, countryId, stateId);

        yield put(addressActions.loadCitiesResponse(cities));
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    }
}

export function* watchAddress() {
    yield takeLatest(getType(addressActions.loadCountries), watchLoadCountries);
    yield takeLatest(getType(addressActions.loadStates), watchLoadStates);
    yield takeLatest(getType(addressActions.loadCities), watchLoadCities);
}
