import { call, put, select, takeEvery } from "redux-saga/effects";
import { ActionType, getType } from "typesafe-actions";
import { pushBwicActions } from "../actions";
import { apiOperationActions } from "../actions/api-operation.actions";
import { blastMessageActions } from "../actions/blast-message.actions";
import { errorActions } from "../actions/error.actions";
import { feedbackTypes } from "../constants/bidding";
import { RequestState } from "../constants/request-state";
import { getSecuritiesWithLatestBids } from "../selectors/latest-bids.selector";
import { blastMessageService } from "../services/blast-message.service";
import { ApiOperationType } from "../types/api-operation/ApiOperationType";
import { Bid } from "../types/bidding/Bid";
import { BidRequestType } from "../types/bidding/BidRequest";
import { BlastMessage } from "../types/blast-message/BlastMessage";
import { BwicPosition } from "../types/bwic/BwicPosition";
import { CompanySlim } from "../types/company/CompanySlim";
import { OpenBiddingStatus } from "../types/enums/OpenBiddingStatus";
import { BwicProcessType, Process } from "../types/models/Process";
import { AppState } from "../types/state/AppState";
import { BlastMessageRecipientLevelState, BlastMessageRecipientsState, BlastMessageState, BlastMessageTypes } from "../types/state/BlastMessageState";
import { isActiveTrade, TradeStatus } from "../types/trades/TradeStatus";
import { apiUtils } from "../utils/api.utils";
import { BidCompanyIdentifierParseResult, biddingUtils } from "../utils/bidding.utils";
import { calculateStage2Participants } from "../utils/stage2-participants-calculator";

export function* watchMessageChange() {
    yield updateRecipients();
}

export function* watchBwicChange() {
    yield updateRecipients(true);
}

function* updateRecipients(keepSelection = false) {
    const panelVisible: boolean = yield select((s: AppState) => s.blastMessage.panelVisible);
    const bwicParticipants: CompanySlim[] = yield select((s: AppState) => s.bidding.bwic?.companies);
    const process: Process = yield select((s: AppState) => s.bidding.bwic?.process);
    const securities: BwicPosition[] = yield select((s: AppState) => getSecuritiesWithLatestBids({ securities: s.sellerBidding.securities }));

    if (!panelVisible || !bwicParticipants || !bwicParticipants.length || !securities || !securities.length || !process) return;

    const isSelectAllRecipients: boolean = yield select((s: AppState) => s.blastMessage.isSelectAllRecipients);
    const messageType: number = yield select((s: AppState) => s.blastMessage.messageType);
    const recipientLevel: BlastMessageRecipientLevelState = yield select((s: AppState) => s.blastMessage.recipientLevel);
    const recipients: BlastMessageRecipientsState = yield getRecipients(messageType, recipientLevel, bwicParticipants, securities, process);

    if (keepSelection) {
        const currentRecipients: BlastMessageRecipientsState = yield select((s: AppState) => s.blastMessage.recipients);
        Object
            .keys(recipients)
            .forEach((key: string) => {
                if (currentRecipients.hasOwnProperty(key)) {
                    recipients[key] = currentRecipients[key];
                }
            });
    }

    yield put(blastMessageActions.storeRecipients(recipients));
    yield updateSelectAllRecipientsState(recipients, isSelectAllRecipients);
}

function* getRecipients(
    messageType: number,
    recipientLevel: BlastMessageRecipientLevelState = BlastMessageRecipientLevelState.Top3,
    bwicParticipants: CompanySlim[],
    securities: BwicPosition[],
    process: Process) {
    const biddingSecurities = securities.filter(s => s.trade == null || s.trade.status === TradeStatus.rejected);

    switch (messageType) {
        case BlastMessageTypes.submitFinal: return (yield getSubmitFinalRecipients(biddingSecurities, recipientLevel, process)) as BlastMessageRecipientsState
        case BlastMessageTypes.stage1AboutToEndTimeout: return (yield getStage1AboutToEndTimeoutRecipients(biddingSecurities)) as BlastMessageRecipientsState
        default: return getBidOrPassRecipients(biddingSecurities, bwicParticipants);
    }
}

function* getSubmitFinalRecipients(securities: BwicPosition[], recipientLevel: BlastMessageRecipientLevelState, process: Process) {
    const isAllToAll: boolean = yield select((s: AppState) => s.bidding.bwic?.isAllToAll);
    let recipients: BlastMessageRecipientsState = {};
    const withBids = securities.filter(s => s.bids?.length);

    for (const position of withBids) {
        let positionRecipients: BlastMessageRecipientsState = {};

        switch (process.type) {
            case BwicProcessType.TopX:
            case BwicProcessType.JumpBall:
                positionRecipients = (yield getStagedProcessRecipients(position, process, isAllToAll)) as BlastMessageRecipientsState;
                break;
            default: positionRecipients = getStandardProcessRecipients(position, recipientLevel, isAllToAll);
        }

        recipients = {
            ...recipients,
            ...positionRecipients
        };
    }

    return recipients;
}

function getDefaultSelectedState(isAllToAll: boolean, isBuyside: boolean){
    return isAllToAll || !isBuyside;
}

function getStage1AboutToEndTimeoutRecipients(securities: BwicPosition[]) {
    let recipients: BlastMessageRecipientsState = {};
    const withBids = securities.filter(s => s.bids?.length);

    for (const position of withBids) {
        position.bids.forEach(b => { recipients[biddingUtils.getBidCompanyIdentifier(b)] = true });
    }

    return recipients;
}

function getStandardProcessRecipients(position: BwicPosition, recipientLevel: BlastMessageRecipientLevelState, isAllToAll: boolean) {
    const getTopImproverCount = () => {
        if (recipientLevel === BlastMessageRecipientLevelState.Top3) return 3;
        if (recipientLevel === BlastMessageRecipientLevelState.Top5) return 5;
        return undefined;
    }

    const recipients: BlastMessageRecipientsState = {};

    // Calculate for selected level
    const topX = getTopImproverCount();
    const targetBids: Bid[] = topX
        ? biddingUtils.groupBidsByLevel(position.bids).slice(0, topX).flat()
        : position.bids;

    targetBids.forEach(b => { recipients[biddingUtils.getBidCompanyIdentifier(b)] = getDefaultSelectedState(isAllToAll, !!b.buyerPseudoOrderNumber) });

    return recipients;
}

function* getStagedProcessRecipients(position: BwicPosition, process: Process, isAllToAll: boolean) {
    const recipients: BlastMessageRecipientsState = {};
    const allBids: Bid[] | undefined = yield select((state: AppState) => state.sellerBidding.securities.find(x => x.id === position.id)?.bids);

    if (allBids?.length) {
        const stage2Participants = calculateStage2Participants(allBids, process);
        Object.keys(stage2Participants).forEach((identifier: string) => {
            if ( // Jump Ball: Skip participants who already submitted Stage 2 bid
                process.type !== BwicProcessType.JumpBall ||
                !allBids.some(b =>
                    b.stagedBiddingStatus === OpenBiddingStatus.stage1Ended &&
                    biddingUtils.getBidCompanyIdentifier(b) === identifier)) {
                const parseResult = biddingUtils.parseBidCompanyIdentifier(identifier) as BidCompanyIdentifierParseResult;
                recipients[identifier] = getDefaultSelectedState(isAllToAll, parseResult.buyerPseudoOrderNumber != null);
            }
        });
    }

    return recipients;
}

function getBidOrPassRecipients(securities: BwicPosition[], bwicParticipants: CompanySlim[]) {
    const participants = bwicParticipants.filter(p =>
        securities.some(s =>
            !s.bids
                ?.filter(b => !b.buyerPseudoOrderNumber)
                .some(b => b.company.id === p.id))
    );
    return apiUtils.normalize(participants, p => String(p.id), () => true);
}

function* watchRecpientSelectedChange(action: ActionType<typeof blastMessageActions.recipientSelectedChange>) {
    const recipients: BlastMessageRecipientsState = yield select((s: AppState) => s.blastMessage.recipients);
    const isSelectAllRecipients: boolean = yield select((s: AppState) => s.blastMessage.isSelectAllRecipients);

    if (recipients[action.payload.companyIdentifier] !== isSelectAllRecipients) {
        yield updateSelectAllRecipientsState(recipients, isSelectAllRecipients);
    }
}

function* updateSelectAllRecipientsState(
    recipients: BlastMessageRecipientsState,
    isSelectAllRecipients: boolean) {
    const shouldToggleSelectAll =
        Object
            .keys(recipients)
            .every((key: string) => recipients[key] === !isSelectAllRecipients);
    if (shouldToggleSelectAll) {
        yield put(blastMessageActions.toggleSelectAllParticipantsFlag());
    }
}

export function* watchSend(action: ActionType<typeof blastMessageActions.send>) {
    const bwicReferenceName: string = yield select((s: AppState) => s.bidding.bwic?.referenceName);
    const { messageType, recipientLevel, stage1AboutToEndTimeout }: BlastMessageState = yield select((s: AppState) => s.blastMessage);
    const process: Process = yield select((s: AppState) => s.bidding.bwic?.process);
    const { blastMessageRecipients, bwicParticipants, securities } = action.payload;

    const recipients = Object
        .keys(blastMessageRecipients)
        .filter((key: string) => blastMessageRecipients[key]);

    if (!bwicReferenceName ||
        !bwicParticipants ||
        !bwicParticipants.length ||
        !securities ||
        !securities.length ||
        !recipients.length ||
        !messageType) return;

    const recipientsByPosition: Map<number, BlastMessageRecipientsState> = new Map();
    const unsold = securities.filter(s => !isActiveTrade(s.trade))

    for (var s of unsold) {
        const recipients: BlastMessageRecipientsState = yield getRecipients(messageType, recipientLevel, bwicParticipants, [s], process);
        recipientsByPosition.set(s.id, recipients);
    }

    function getFeedbackType(messageType: number) {
        switch (messageType) {
            case BlastMessageTypes.stage1AboutToEndTimeout: return feedbackTypes.stage1AboutToEndTimeout
            case BlastMessageTypes.submitFinal: return feedbackTypes.submitFinal
            default: return undefined
        }
    }

    const model: BlastMessage = {
        companyMessages: recipients
            .map(identifier => ({ identifier, ...biddingUtils.parseBidCompanyIdentifier(identifier) }))
            .filter(({ brokerDealerCompanyId }) => brokerDealerCompanyId != null)
            .map(({ identifier, brokerDealerCompanyId, buyerPseudoOrderNumber }) => ({
                brokerDealerCompanyId: brokerDealerCompanyId ?? 0,
                buyerPseudoOrderNumber,
                positionMessages: unsold.filter(s => {
                    const recipients = recipientsByPosition.get(s.id);
                    return recipients && recipients.hasOwnProperty(identifier) && blastMessageRecipients[identifier];
                }).map(s => ({
                    positionId: s.id,
                    bidRequestType: messageType === BidRequestType.BidOrPass ? messageType : undefined,
                    feedbackType: getFeedbackType(messageType),
                    stage1AboutToEndTimeout: messageType === BlastMessageTypes.stage1AboutToEndTimeout ? stage1AboutToEndTimeout : undefined,
                    feedbackBidId: messageType === BlastMessageTypes.submitFinal || messageType === BlastMessageTypes.stage1AboutToEndTimeout
                        ? s.bids?.find(b => biddingUtils.getBidCompanyIdentifier(b) === identifier)?.id
                        : undefined
                }))
            })).filter(cm => cm.positionMessages.length > 0)
    };

    if (!model.companyMessages.length) {
        yield put(blastMessageActions.hide());
        return;
    }

    yield put(blastMessageActions.sending(true));
    try {
        const tokens: string[] = yield call(blastMessageService.send, bwicReferenceName, model);
        if (tokens && tokens.length) {
            yield put(apiOperationActions.waitResult(
                ...tokens.map(token => ({
                    token,
                    event: ApiOperationType.BlastMessage,
                    state: RequestState.request,
                    autoRemove: true,
                    createdDate: new Date()
                }))
            ));
        }
        yield put(blastMessageActions.hide());
    } catch (e) {
        yield put(errorActions.unexpectedError(e));
    } finally {
        yield put(blastMessageActions.sending(false));
    }
}

export function* watchBlastMessage() {
    yield takeEvery([
        getType(blastMessageActions.show),
        getType(blastMessageActions.messageTypeChange),
        getType(blastMessageActions.recipientLevelChange)], watchMessageChange);
    yield takeEvery(getType(blastMessageActions.recipientSelectedChange), watchRecpientSelectedChange);
    yield takeEvery(getType(pushBwicActions.newBid), watchBwicChange);
    yield takeEvery(getType(pushBwicActions.tradeAction), watchBwicChange);
    yield takeEvery(getType(blastMessageActions.send), watchSend);
}
